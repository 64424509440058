import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    width: 200,
    height: 50,
    overflow: 'hidden'
  },
  glitchLogo: {
    width: 200,
    height: 50,
    overflow: 'hidden',
    animation: '$glitch .5s steps infinite',
    '&::before': {
      content: 'url(/static/brand/companyLogo.svg)',
      position: 'absolute',
      left: 24,
      top: 7,
      width: 200,
      height: 50,
      animation: '$glitchTop .5s linear infinite'
    },
    '&::after': {
      content: 'url(/static/brand/companyLogo.svg)',
      position: 'absolute',
      left: 24,
      top: 7,
      width: 200,
      height: 50,
      animation: '$glitchBottom .75s linear infinite'
    }
  },
  '@keyframes glitch': {
    '2%,64%': {
      transform: 'skew(2deg)'
    },
    '4%,60%': {
      transform: 'skew(5deg)'
    },
    '62%': {
      transform: 'translate(-18px,8px) skew(8deg)'
    }
  },
  '@keyframes glitchTop': {
    '2%,64%': {
      transform: 'skew(-4deg)'
    },
    '4%,60%': {
      transform: 'skew(-8deg)'
    },
    '62%': {
      transform: 'translate(18px,-8px) skew(-13deg)'
    }
  },
  '@keyframes glitchBottom': {
    '2%,64%': {
      transform: 'translate(0px,0)'
    },
    '4%,60%': {
      transform: 'translate(0px,0)'
    },
    '62%': {
      transform: 'translate(-18px,8px) skew(21deg)'
    }
  },
  whiteLetters: {
    fill: theme.palette.common.white
  },
  circleV: {
    fill: theme.palette.vanscend.green
  }
}));

const LogoGlitch = () => {
  const classes = useStyles();
  const [scrollingDown, setScrollingDown] = useState(false);
  const [scrollingUp, setScrollingUp] = useState(false);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset;
      if (currentPosition > offset) {
        //scrollingDown
        setScrollingDown(true);
        setScrollingUp(false);
      } else {
        //scrollingUp
        setScrollingDown(false);
        setScrollingUp(true);
      }
      setOffset(currentPosition);
    }

    window.addEventListener('scroll', onScroll);
    return () => {
      setTimeout(() => {
        setScrollingDown(false);
        setScrollingUp(false);
      }, 750);
      window.removeEventListener('scroll', onScroll);
    };
  }, [offset]);

  return (
    <div
      className={
        scrollingUp || scrollingDown
          ? classes.glitchLogo
          : classes.logoContainer
      }
    >
      <svg viewBox="0 0 611.11 140.29">
        <g data-name="Lettering">
          <path
            id="letterA"
            className={classes.whiteLetters}
            d="M191.64,41V99.49H172.09V93.66c-3.89,4.64-9.61,6.8-17.28,6.8-15.88,0-28.73-11.66-28.73-30.35S138.93,40,154.81,40c6.8,0,12.31,1.94,16.3,6.05V41Zm-20.2,29.16c0-8.85-5.4-13.82-12.31-13.82s-12.32,5-12.32,13.82,5.4,14,12.32,14S171.44,79.08,171.44,70.11Z"
          />
          <path
            id="letterN1"
            className={classes.whiteLetters}
            d="M267.13,66V99.49H246.61V69.36c0-8.43-3.68-12-9.62-12-6.58,0-11.66,4.1-11.66,13.82v28.3H204.81V41h19.55v6.37C229,42.46,235.59,40,242.93,40,256.65,40,267.13,47.86,267.13,66Z"
          />
          <path
            id="letterS"
            className={classes.whiteLetters}
            d="M275.33,94.41l6.16-14c5.4,3.35,13.28,5.4,20.41,5.4,6.91,0,9.18-1.51,9.18-4,0-7.89-34.56,1.08-34.56-22.36C276.52,48.4,286.67,40,305,40c8.21,0,17.28,1.73,23.12,5L322,58.88a34.29,34.29,0,0,0-16.85-4.32c-6.91,0-9.39,1.94-9.39,4.1,0,8.1,34.45-.64,34.45,22.58,0,10.8-9.94,19.22-28.95,19.22C291.32,100.46,281.06,98,275.33,94.41Z"
          />
          <path
            id="letterC"
            className={classes.whiteLetters}
            d="M335.38,70.11C335.38,52.51,349.31,40,369,40c13.28,0,23.44,5.94,27.43,16.09l-15.87,8.1c-2.81-5.51-6.92-7.88-11.67-7.88-7.13,0-12.74,5-12.74,13.82,0,9.07,5.61,14,12.74,14,4.75,0,8.86-2.37,11.67-7.88l15.87,8.1c-4,10.15-14.15,16.09-27.43,16.09C349.31,100.46,335.38,87.93,335.38,70.11Z"
          />
          <path
            id="letterE"
            className={classes.whiteLetters}
            d="M448.35,79.72,459,91c-5.51,6.26-13.71,9.5-24.84,9.5-20.84,0-34.34-12.85-34.34-30.35S413.68,40,432.26,40c16.85,0,31,10.69,31.1,29.81l-41.79,8c2.26,4.75,6.8,7,13.28,7A18.76,18.76,0,0,0,448.35,79.72ZM420,67.3l23.76-4.64c-1.41-4.86-5.62-8.1-11.34-8.1C425.35,54.56,420.59,59,420,67.3Z"
          />
          <path
            id="letterN2"
            className={classes.whiteLetters}
            d="M535.18,66V99.49H514.66V69.36c0-8.43-3.67-12-9.61-12-6.59,0-11.66,4.1-11.66,13.82v28.3H472.86V41h19.55v6.37C497.06,42.46,503.65,40,511,40,524.71,40,535.18,47.86,535.18,66Z"
          />
          <path
            id="letterD"
            className={classes.whiteLetters}
            d="M611.11,19.35V99.49H591.56V93.66c-3.89,4.64-9.61,6.8-17.18,6.8-16,0-28.83-11.66-28.83-30.35S558.4,40,574.38,40c6.81,0,12.32,1.94,16.2,6.05V19.35Zm-20.2,50.76c0-8.85-5.29-13.82-12.21-13.82s-12.31,5-12.31,13.82,5.29,14,12.31,14S590.91,79.08,590.91,70.11Z"
          />
        </g>
        <g data-name="Logo">
          <path
            id="circle"
            className={classes.circleV}
            d="M70.14,140.29A70.15,70.15,0,1,1,92.92,3.78a7.1,7.1,0,1,1-4.61,13.43A55.91,55.91,0,1,0,121.45,47.8a7.1,7.1,0,1,1,13-5.68,70.17,70.17,0,0,1-64.32,98.17Z"
          />
          <path
            id="letterV"
            className={classes.circleV}
            d="M126.32,10.27l-.93-.53a13.9,13.9,0,0,0-19,5.09L70.84,76.39,51.94,46.13a3.91,3.91,0,0,0-5.38-1.25l-.84.53a13.91,13.91,0,0,0-4.43,19.17L62.68,98.84a9.39,9.39,0,0,0,4.78,3.85,9.4,9.4,0,0,0,12.12-3.82l48.14-83.38A3.83,3.83,0,0,0,126.32,10.27Z"
          />
        </g>
      </svg>
    </div>
  );
};

export default LogoGlitch;
