import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  spinner: {
    position: 'relative'
  },
  bottom: {
    color: theme.palette.grey[200]
  },
  top: {
    color: '#8CC63F',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  }
}));

const Spinner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.spinner}>
        <CircularProgress
          variant='determinate'
          className={classes.bottom}
          size={80}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant='indeterminate'
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle
          }}
          size={80}
          thickness={4}
        />
      </div>
    </div>
  );
};

export default Spinner;
