import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  LinearProgress,
  makeStyles,
  withStyles,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Movie as MovieIcon, ExpandMore } from '@material-ui/icons';
import { formatVideoTime } from '../utils';

const VideoLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.background.light
  }
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {},
  skeleton: {
    width: '300px'
  },
  listContainer: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  movieIcon: {
    backgroundColor: theme.palette.background.default
  },
  qaAccordion: {
    paddingBottom: theme.spacing(2)
  },
  qaAccordionDetails: {
    flexDirection: 'column',
    backgroundColor: '#EEE'
  },
  question: {
    color: theme.palette.vanscend.slate,
    fontStyle: 'italic',
    fontWeight: 'bold'
  },
  answer: {
    color: theme.palette.vanscend.blue,
    marginLeft: theme.spacing(1),
    fontWeight: 'bold'
  },
  lessonQaTitle: {
    fontSize: '1rem'
  },
  qaAccordionSummary: {
    backgroundColor: '#EEE'
  }
}));

const generateSkeletons = (element) =>
  [0, 1].map((value) => React.cloneElement(element, { key: value }));

const VideoList = ({ videos, userVideos, watchVideo, clusterName }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true
  });

  const onStartButtonClick = async (video, progress, percentWatched) => {
    await watchVideo(video, clusterName);
    navigate(
      `${location.pathname}/${video.id}?userProgress=${
        percentWatched === 100 ? 0 : progress
      }&clusterName=${clusterName}`
    );
  };

  return (
    <>
      {videos.length === 0 ? (
        <div>
          <List>
            {generateSkeletons(
              <ListItem>
                <ListItemAvatar>
                  <Skeleton variant="circle" width={40} height={40} />
                </ListItemAvatar>
                <div className={classes.skeleton}>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </div>
              </ListItem>
            )}
          </List>
        </div>
      ) : (
        <div className={classes.listContainer}>
          <List>
            {videos.map((video) => {
              const userVideo = userVideos.find(
                (userVideo) => userVideo.video.id === video.id
              );
              const complete = userVideo && userVideo.completed;
              const watched = userVideo && userVideo.watched;
              const progress = userVideo ? userVideo.lengthWatched : 0;
              const percentWatched = userVideo
                ? Math.round(
                    (Math.round(userVideo.lengthWatched) /
                      Math.floor(video.length)) *
                      100,
                    0
                  )
                : 0;
              const userQA =
                userVideo && userVideo.qa.length ? (
                  <Accordion>
                    <AccordionSummary
                      className={classes.qaAccordionSummary}
                      expandIcon={<ExpandMore />}
                    >
                      <Typography
                        variant="h6"
                        className={classes.lessonQaTitle}
                      >
                        {isMobile ? 'Lesson Q&A' : 'Lesson Questions & Answers'}
                      </Typography>
                    </AccordionSummary>
                    {userVideo.qa.map((qa) => (
                      <AccordionDetails
                        key={qa.question}
                        className={classes.qaAccordionDetails}
                      >
                        <Typography className={classes.question}>
                          {qa.question}
                        </Typography>
                        <Typography className={classes.answer}>
                          {qa.answer}
                        </Typography>
                      </AccordionDetails>
                    ))}
                  </Accordion>
                ) : (
                  <span />
                );
              return (
                <Grid container alignItems="center" key={video.id}>
                  <Grid item xs={12} md={8} lg={10}>
                    <ListItem
                      style={{ paddingLeft: 0 }}
                      alignItems="flex-start"
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.movieIcon}>
                          {watched && complete ? (
                            <img
                              alt="Completed Video Checkmark"
                              src="/static/brand/companyLogoStamp.svg"
                            />
                          ) : (
                            <MovieIcon style={{ color: 'black' }} />
                          )}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6">{video.name}</Typography>
                        }
                        secondary={
                          <>
                            {video.description && (
                              <Typography component="span" variant="body2">
                                Related Lessons: {video.description}
                                <br />
                              </Typography>
                            )}
                            <Typography component="span" variant="caption">
                              Duration: {formatVideoTime(video.length)}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={2}
                    direction="column"
                    justifyContent="center"
                    xs={12}
                    md={4}
                    lg={2}
                  >
                    <Grid item>
                      <Button
                        style={{
                          backgroundColor:
                            watched && !complete
                              ? '#FF6600'
                              : complete
                              ? '#00FF00'
                              : percentWatched
                              ? '#FFFF00'
                              : '#E0E0E0'
                        }}
                        variant="contained"
                        fullWidth
                        onClick={() =>
                          onStartButtonClick(video, progress, percentWatched)
                        }
                      >
                        {watched && !complete
                          ? 'Answer'
                          : percentWatched === 100 ||
                            (percentWatched === 0 && complete)
                          ? 'Restart'
                          : percentWatched
                          ? 'Resume'
                          : 'Start'}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Box display="flex" alignItems="center" width="100%">
                        <Box minWidth="90%" mr={2}>
                          <VideoLinearProgress
                            variant="determinate"
                            value={percentWatched}
                          />
                        </Box>
                        <Box minWidth={35}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >{`${percentWatched}%`}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.qaAccordion}>
                    {userQA}
                  </Grid>
                </Grid>
              );
            })}
          </List>
        </div>
      )}
    </>
  );
};

VideoList.propTypes = {
  videos: PropTypes.array.isRequired,
  userVideos: PropTypes.array.isRequired,
  watchVideo: PropTypes.func.isRequired,
  clusterName: PropTypes.string
};

export default VideoList;
