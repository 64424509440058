import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
import {
  Box,
  Button,
  Grid,
  TextField,
  FormHelperText,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: theme.palette.vanscend.white
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: theme.palette.vanscend.white
    },
    '& input': {
      color: theme.palette.vanscend.white
    },
    '& textarea': {
      color: theme.palette.vanscend.white
    }
  },
  focused: {},
  notchedOutline: {},
  submitButton: {
    color: theme.palette.vanscend.black
  },
  callout: {
    color: theme.palette.vanscend.green
  }
}));

const ProspectForm = ({ formik }) => {
  const classes = useStyles();
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              style={{ marginTop: 10 }}
              fullWidth
              variant="outlined"
              type="text"
              label="Name"
              name="name"
              autoComplete="name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              InputProps={{
                classes: {
                  root: classes.root,
                  notchedOutline: classes.notchedOutline
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              style={{ marginTop: 10 }}
              fullWidth
              variant="outlined"
              type="email"
              label="Email address"
              name="email"
              autoComplete="email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              InputProps={{
                classes: {
                  root: classes.root,
                  notchedOutline: classes.notchedOutline
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              style={{ marginTop: 10 }}
              fullWidth
              variant="outlined"
              type="text"
              label="Zip Code"
              name="zipCode"
              autoComplete="zipCode"
              {...getFieldProps('zipCode')}
              error={Boolean(touched.zipCode && errors.zipCode)}
              helperText={touched.zipCode && errors.zipCode}
              InputProps={{
                classes: {
                  root: classes.root,
                  notchedOutline: classes.notchedOutline
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ marginTop: 10, fontSize: '1.4em' }}>
              <span className={classes.callout}>We want your input!</span> Feel
              free to suggest topics or lessons we can add to the curriculum:
            </Typography>
            <TextField
              style={{ marginTop: 10 }}
              fullWidth
              variant="outlined"
              multiline
              rows={8}
              label="Comment"
              name="comment"
              autoComplete="comment"
              {...getFieldProps('comment')}
              error={Boolean(touched.comment && errors.comment)}
              helperText={touched.comment && errors.comment}
              InputProps={{
                classes: {
                  root: classes.root,
                  notchedOutline: classes.notchedOutline
                }
              }}
            />
          </Grid>
        </Grid>
        <FormHelperText error={Boolean(errors.afterSubmit)}>
          {errors.afterSubmit && errors.afterSubmit.message}
        </FormHelperText>
        <Box textAlign="center" style={{ marginTop: 20 }}>
          <Button
            className={classes.submitButton}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};

ProspectForm.propTypes = {
  formik: PropTypes.object.isRequired
};

export default ProspectForm;
