import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Grid, makeStyles } from '@material-ui/core';

import { Page } from '../../../../components';
import ProfileAvatar from './ProfileAvatar';
import ProfileDetails from './ProfileDetails';
import { updateProfileDetails } from '../../../../redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  section: {
    minHeight: '100%',
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(3),
    borderRadius: 8
  }
}));

const MyProfile = () => {
  const classes = useStyles();
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const ProfileSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    userName: Yup.string().required('username is required'),
    rate: Yup.number().required('Your time is worth something!')
  });

  const formik = useFormik({
    initialValues: {
      ...profile
    },
    validationSchema: ProfileSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      const { firstName, lastName, userName, rate, avatar } = values;
      const avatarLink = avatar ?? profile.avatar ?? '';
      try {
        await dispatch(
          updateProfileDetails(
            profile.id,
            firstName,
            lastName,
            userName,
            rate,
            avatarLink
          )
        );
        setSubmitting(false);
      } catch (err) {
        setSubmitting(false);
        setErrors({ afterSubmit: err });
      }
    }
  });

  return (
    <Page title="My Profile" className={classes.root}>
      <div className={classes.section}>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <ProfileAvatar profile={profile} />
          </Grid>
          <Grid item md={8} xs={12}>
            <ProfileDetails formik={formik} />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default MyProfile;
