import React from 'react';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Container,
  Typography,
  Hidden
} from '@material-ui/core';
import { Page, Header } from '../../../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.vanscend.black,
    overflow: 'hidden',
    padding: 0
  },
  headerGrid: {
    marginTop: -80,
    [theme.breakpoints.down('sm')]: {
      marginTop: -50
    }
  },
  headerImg: {
    width: '100%',
    height: '100%'
  },
  descContainer: {
    padding: '5vw 3vw 30vh',
    color: theme.palette.common.white,
    maxWidth: 1220,
    [theme.breakpoints.up('md')]: {
      padding: '5vw 1vw 30vh'
    }
  },
  tagLine: {
    padding: '20px 0 40px',
    fontSize: '2rem'
  },
  callout: {
    color: theme.palette.vanscend.green
  },
  description: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  myVanscendLink: {
    textDecoration: 'underline'
  },
  noDisplayXsUp: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}));

const MyVanscendLandingPage = () => {
  const classes = useStyles();

  return (
    <Page title="My Vanscend">
      <div className={classes.root}>
        <Header title="MyVanscend" />
        <Grid container spacing={0} className={classes.headerGrid}>
          <Grid item xs={12} sm={4}>
            <img
              className={classes.headerImg}
              src="/static/images/myVanscend/farLeftPHx.png"
            />
          </Grid>
          <Hidden xsDown>
            <Grid item sm={3}>
              <img
                className={classes.headerImg}
                src="/static/images/myVanscend/midLeftPHx2.jpg"
              />
            </Grid>
          </Hidden>
          <Hidden xsDown>
            <Grid item sm={3}>
              <img
                className={classes.headerImg}
                src="/static/images/myVanscend/midRightPHx2.jpg"
              />
            </Grid>
          </Hidden>
          <Hidden xsDown>
            <Grid item sm={2}>
              <img
                className={classes.headerImg}
                src="/static/images/myVanscend/farRightPHx2.jpg"
              />
            </Grid>
          </Hidden>
        </Grid>
        <Container className={classes.descContainer}>
          <Typography variant="h1" className={classes.tagLine}>
            Vanscend<span className={classes.callout}>&apos;</span>s{' '}
            <span className={classes.callout}>curriculum</span> makes{' '}
            <span className={classes.callout}>entrepreneurship</span> more{' '}
            <span className={classes.callout}>accessible to you.</span>
          </Typography>
          <Typography className={classes.description}>
            These 100 free video lessons help you to learn more about
            entrepreneurship and yourself.
            <br />
            <br />
            Access the curriculum at <br className={classes.noDisplayXsUp} />
            <Link to="/auth/login" className={classes.myVanscendLink}>
              MyVanscend Here
            </Link>
          </Typography>
        </Container>
      </div>
    </Page>
  );
};

export default MyVanscendLandingPage;
