import React from 'react';
import PropTypes from 'prop-types';
import { Badge, makeStyles } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 999,
    right: 0,
    display: 'flex',
    cursor: 'pointer',
    position: 'fixed',
    alignItems: 'center',
    top: theme.spacing(16),
    height: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.25),
    boxShadow: theme.shadows[5],
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    transition: theme.transitions.create('opacity'),
    '&:hover': { opacity: 0.72 }
  },
  shoppingCart: {
    width: 24,
    height: 24
  }
}));

const CartWidget = ({ onOpenCart, cartCount }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={onOpenCart}>
      <Badge showZero badgeContent={cartCount} color="error" max={99}>
        <ShoppingCartIcon className={classes.shoppingCart} />
      </Badge>
    </div>
  );
};

CartWidget.propTypes = {
  onOpenCart: PropTypes.func,
  cartCount: PropTypes.number
};

export default CartWidget;
