import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HomeIcon from '@material-ui/icons/Home';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import SchoolIcon from '@material-ui/icons/School';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import EmailIcon from '@material-ui/icons/Email';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/Info';

import { NavItem } from '.';
import { logout } from '../redux';

const items = [
  {
    href: '/',
    icon: HomeIcon,
    title: 'Home'
  },
  {
    href: '/my-vanscend',
    icon: SchoolIcon,
    title: 'MyVanscend'
  },
  {
    href: '/support',
    icon: ShoppingBasketIcon,
    title: 'Support'
  },
  {
    href: '/about',
    icon: InfoIcon,
    title: 'About'
  },
  {
    href: '/impact',
    icon: AccessibilityNewIcon,
    title: 'Impact'
  },
  {
    href: '/contact',
    icon: EmailIcon,
    title: 'Contact'
  }
];

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 240
  },
  avatar: {
    cursor: 'pointer',
    width: theme.spacing(8),
    height: theme.spacing(8)
    // width: 64,
    // height: 64
  },
  name: {
    [theme.breakpoints.up('lg')]: {
      color: theme.palette.text.light
    },
    paddingTop: theme.spacing(1)
  },
  value: {
    [theme.breakpoints.up('lg')]: {
      color: theme.palette.text.light
    }
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  title: {
    marginRight: 'auto'
  },
  menuClose: {
    position: 'absolute'
  },
  logoContainer: {
    padding: '20px',
    backgroundColor: 'black'
  }
}));

const MainNavDrawer = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.profile);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleLogout = async () => {
    try {
      onMobileClose();
      await dispatch(logout());
    } catch (err) {
      alert(err.message);
    }
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {auth.isAuthenticated ? (
        <Box alignItems="center" display="flex" flexDirection="column" p={2}>
          <Avatar
            className={classes.avatar}
            component={RouterLink}
            src={profile.avatar}
            to="/profile"
          />
          <Typography className={classes.name} color="textPrimary" variant="h5">
            {`${profile.firstName} ${profile.lastName}`}
          </Typography>
          <Typography
            className={classes.value}
            color="textPrimary"
            variant="h6"
          >
            {`Current Value: $${parseFloat(profile.value)}`}
          </Typography>
        </Box>
      ) : (
        <Box paddingLeft={2} paddingRight={2}>
          <List>
            <NavItem href="/auth/login" title="Log In" icon={LockIcon} />
            <NavItem
              href="/auth/register"
              title="Sign Up"
              icon={PersonAddIcon}
            />
          </List>
        </Box>
      )}
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={
                item.href === '/my-vanscend' && auth.isAuthenticated
                  ? '/my-vanscend/learning'
                  : item.href
              }
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Divider />
      {auth.isAuthenticated && (
        <Box paddingLeft={2} paddingRight={2}>
          <List>
            <ListItem className={classes.item} disableGutters>
              <Button className={classes.button} onClick={handleLogout}>
                <span className={classes.title}>Log Out</span>
                <ExitToAppIcon />
              </Button>
            </ListItem>
          </List>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

MainNavDrawer.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default MainNavDrawer;
