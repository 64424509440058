import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  InputAdornment
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  cardActions: {
    padding: theme.spacing(2)
  }
}));

const ProfileDetails = ({ formik }) => {
  const classes = useStyles();
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardHeader title="Profile" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="First name"
                  name="firstName"
                  required
                  {...getFieldProps('firstName')}
                  variant="outlined"
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Last name"
                  name="lastName"
                  required
                  {...getFieldProps('lastName')}
                  variant="outlined"
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Username"
                  name="userName"
                  required
                  {...getFieldProps('userName')}
                  variant="outlined"
                  error={Boolean(touched.userName && errors.userName)}
                  helperText={touched.userName && errors.userName}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email Address"
                  name="accountId"
                  type="email"
                  disabled
                  {...getFieldProps('accountId')}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  label="What is an hour of your time worth?"
                  name="rate"
                  required
                  {...getFieldProps('rate')}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">/hr.</InputAdornment>
                    )
                  }}
                  error={Boolean(touched.rate && errors.rate)}
                  helperText={touched.rate && errors.rate}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Grid
            item
            container
            justifyContent="flex-end"
            className={classes.cardActions}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              type="submit"
            >
              Save details
            </Button>
          </Grid>
        </Card>
      </Form>
    </FormikProvider>
  );
};

ProfileDetails.propTypes = {
  formik: PropTypes.object.isRequired
};

export default ProfileDetails;
