import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ReactPlayer from 'react-player/vimeo';

const useStyles = makeStyles(() => ({
  root: {},
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%'
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0
  }
}));

const VideoPlayer = ({ url, progress, updateProgress }) => {
  const classes = useStyles();

  const seek = (player) => {
    player.seekTo(Number(progress));
  };

  return (
    <Box width="100%" padding={4}>
      <div className={classes.playerWrapper}>
        {!url ? (
          <Skeleton
            variant="rect"
            className={classes.reactPlayer}
            width="100%"
            height="100%"
          />
        ) : (
          <ReactPlayer
            url={url}
            width="100%"
            height="100%"
            controls
            className={classes.reactPlayer}
            onReady={seek}
            onPause={updateProgress}
            onEnded={updateProgress}
          />
        )}
      </div>
    </Box>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  progress: PropTypes.string.isRequired,
  updateProgress: PropTypes.func.isRequired
};

export default VideoPlayer;
