import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Spinner from './Spinner';
import VideoList from './VideoList';

const useStyles = makeStyles((theme) => ({
  accordion: {
    margin: '0 !important'
  },
  subHeading: {
    fontSize: '1rem',
    lineHeight: '2rem',
    marginRight: '0.75rem',
    fontWeight: 'bold',
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  heading: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: theme.palette.vanscend.blue,
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem'
    }
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

const clusterNames = [
  'Intro to Vanscend',
  'Startup Essentials',
  'Intro to Entrepreneurship',
  'Business Fundamentals',
  'Entrepreneurial Paths',
  'Sweat Equity Entrepreneurship',
  'Dealmaking',
  'Financial Freedom',
  'School of Life',
  'School of You'
];

const VideoGroup = ({
  videos,
  userVideos,
  watchVideo,
  expandedClusterName
}) => {
  const [clusteredVideos, setClusteredVideos] = useState({});
  const [expanded, setExpanded] = useState(
    expandedClusterName ?? clusterNames[0]
  );
  const classes = useStyles();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const groupVideos = useCallback(() => {
    const groupedVideos = videos.reduce(
      (acc, video, index) => {
        const clusterIndex = Math.floor(index / 10);
        const clusterName = clusterNames[clusterIndex];
        if (!acc[clusterName]) {
          acc[clusterName] = [];
        }
        acc[clusterName].push(video);
        return acc;
      },
      {}
    );
    setClusteredVideos(groupedVideos);
  }, [videos]);

  useEffect(() => {
    groupVideos();
  }, [groupVideos]);

  if (Object.entries(clusteredVideos).length === 0) {
    return <Spinner />;
  }

  return Object.entries(clusteredVideos).map(([clusterName, videos], i) => (
    <Accordion
      key={clusterName}
      expanded={expanded === clusterName}
      onChange={handleChange(clusterName)}
      className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${clusterName}-content`}
        id={`${clusterName}-header`}
      >
        <Typography className={classes.subHeading}>
          Cluster #{i + 1}:
        </Typography>
        <Typography className={classes.heading}>{clusterName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <VideoList
          videos={videos}
          userVideos={userVideos}
          watchVideo={watchVideo}
          clusterName={clusterName}
        />
      </AccordionDetails>
    </Accordion>
  ));
};

VideoGroup.propTypes = {
  videos: PropTypes.array.isRequired,
  userVideos: PropTypes.array.isRequired,
  watchVideo: PropTypes.func.isRequired,
  expandedClusterName: PropTypes.string
};

export default VideoGroup;
