import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import useStyles from '../styles';

const InterestsForm = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.formContentInterests}>
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <label>
            <Field
              type="checkbox"
              name="interests"
              value="Entrepreneurship"
              className={classes.formCheckbox}
            />
            Entrepreneurship
          </label>
        </Grid>
        <Grid item xs={4}>
          <label>
            <Field
              type="checkbox"
              name="interests"
              value="Technology"
              className={classes.formCheckbox}
            />
            Technology
          </label>
        </Grid>
        <Grid item xs={4}>
          <label>
            <Field
              type="checkbox"
              name="interests"
              value="Wellness"
              className={classes.formCheckbox}
            />
            Wellness
          </label>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <label>
            <Field
              type="checkbox"
              name="interests"
              value="Retail"
              className={classes.formCheckbox}
            />
            Retail
          </label>
        </Grid>
        <Grid item xs={4}>
          <label>
            <Field
              type="checkbox"
              name="interests"
              value="E-Commerce"
              className={classes.formCheckbox}
            />
            E-Commerce
          </label>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InterestsForm;
