import * as Yup from 'yup';

import profileFormModel from './profileFormModel';

const {
  formField: { firstName, lastName, username, interests }
} = profileFormModel;

export default [
  Yup.object().shape({
    [firstName.name]: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too Long!')
      .required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too Long!')
      .required(`${lastName.requiredErrorMsg}`)
  }),
  Yup.object().shape({
    [username.name]: Yup.string()
      .min(3, 'Too Short!')
      .max(12, 'Too Long!')
      .required(`${username.requiredErrorMsg}`)
  }),
  Yup.object().shape({
    [interests.name]: Yup.array().min(1, `${interests.requiredErrorMsg}`)
  })
];
