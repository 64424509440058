import { handleResponse, handleError } from './apiUtils';

const baseUrl = process.env.REACT_APP_CONTACT_API_URL;

export const isEarlyAdopter = async () => {
  try {
    let response = await fetch(`${baseUrl}/earlyAdopter`, {
      method: 'GET'
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const submitContact = async (contactForm) => {
  try {
    let response = await fetch(`${baseUrl}/contact`, {
      method: 'POST',
      body: JSON.stringify(contactForm)
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};
