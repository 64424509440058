import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cartItems: [],
    donation: 0
  },
  reducers: {
    addToCart: (state, action) => {
      const { product } = action.payload;
      let cartItemIndex;
      cartItemIndex = state.cartItems.findIndex(
        (item) => item.product === product.id
      );
      if (cartItemIndex !== -1) {
        state.cartItems[cartItemIndex].qty =
          state.cartItems[cartItemIndex].qty + 1;
      } else {
        state.cartItems.push({
          product: product.id,
          qty: 1
        });
      }
    },
    removeFromCart: (state, action) => {
      const { product } = action.payload;
      state.cartItems = state.cartItems.filter(
        (cartItem) => cartItem.product !== product
      );
    },
    updateCartItem: (state, action) => {
      const { product, qty } = action.payload;
      const cartItem = state.cartItems.find((item) => item.product === product);
      if (cartItem) {
        if (qty > 0) {
          cartItem.qty = qty;
        } else {
          state.cartItems = state.cartItems.filter(
            (cartItem) => cartItem.product !== product
          );
        }
      }
    },
    updateDonation: (state, action) => {
      state.donation = action.payload;
    },
    clearCart: (state) => {
      state.cartItems = [];
      state.donation = 0;
    }
  }
});

const { actions, reducer } = cartSlice;

export const {
  addToCart,
  removeFromCart,
  updateCartItem,
  updateDonation,
  clearCart
} = actions;

export default reducer;
