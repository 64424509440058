import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  makeStyles,
  Box,
  Button,
  Container,
  Typography
} from '@material-ui/core';

import { Page, LogoInverse } from '../../../components';
import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordVerification from './ResetPasswordVerification';
import { login } from '../../../redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    top: 0,
    left: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5)
    }
  }
}));

const ResetPasswordView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sent, setSent] = useState(false);

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required')
  });

  const VerifyResetPasswordSchema = Yup.object().shape({
    code: Yup.string().required(
      'Verification Code is required. Please check your email.'
    ),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    confirmPassword: Yup.string().when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match')
    })
  });

  const resetPasswordFormik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await Auth.forgotPassword(values.email);
        setSent(true);
        setSubmitting(false);
      } catch (err) {
        setErrors({ afterSubmit: err });
        setSubmitting(false);
      }
    }
  });

  const verifyResetPasswordFormik = useFormik({
    initialValues: {
      code: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: VerifyResetPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await Auth.forgotPasswordSubmit(
          resetPasswordFormik.values.email,
          values.code,
          values.password
        );
        await dispatch(
          login({
            email: resetPasswordFormik.values.email,
            password: values.password
          })
        );
        navigate('/my-vanscend/learning');
      } catch (err) {
        setErrors({ afterSubmit: err });
      } finally {
        setSubmitting(false);
      }
    }
  });

  return (
    <Page title="Reset Password" className={classes.root}>
      <header className={classes.header}>
        <RouterLink to="/">
          <LogoInverse />
        </RouterLink>
      </header>

      <Container>
        <Box style={{ maxWidth: 480, margin: 'auto' }}>
          {!sent ? (
            <>
              <Box style={{ marginBottom: 10 }}>
                <Typography variant="h4" gutterBottom>
                  Forgot your password?
                </Typography>
                <Typography color="textSecondary">
                  Please enter the email address associated with your account.
                  <br />A confirmation code to reset your password will be sent
                  to your email. Please do not close this page after submitting.
                </Typography>
              </Box>
              <Box style={{ marginTop: 5, marginBottom: 2 }}>
                <ResetPasswordForm formik={resetPasswordFormik} />
              </Box>
              <Button
                fullWidth
                variant="contained"
                size="large"
                component={RouterLink}
                to="/auth/login"
              >
                Back
              </Button>
            </>
          ) : (
            <Box style={{ textAlign: 'center' }}>
              <Box style={{ marginBottom: 5 }}>
                <Box
                  component="img"
                  alt="sent email"
                  height={100}
                  src="/static/illustrations/illustration_email_sent.svg"
                  style={{ marginBottom: 5, margin: 'auto' }}
                />
                <Typography variant="h3" gutterBottom>
                  Request sent successfully
                </Typography>
                <Typography>
                  A verification code was sent via email to &nbsp;
                  <strong>{resetPasswordFormik.values.email}</strong>
                  <br />
                  Please check your email and then come back here
                </Typography>
              </Box>
              <Box style={{ marginTop: 5, marginBottom: 2 }}>
                <ResetPasswordVerification formik={verifyResetPasswordFormik} />
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default ResetPasswordView;
