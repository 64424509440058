export default {
  formId: 'profileForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'First name*',
      requiredErrorMsg: 'First name is required'
    },
    lastName: {
      name: 'lastName',
      label: 'Last name*',
      requiredErrorMsg: 'Last name is required'
    },
    username: {
      name: 'username',
      label: 'handle*',
      requiredErrorMsg: 'A handle is required'
    },
    interests: {
      name: 'interests',
      label: 'Interests*',
      requiredErrorMsg: 'At least one interest is required'
    }
  }
};
