import React from 'react';
import clsx from 'clsx';
import { makeStyles, Hidden, Typography } from '@material-ui/core';
import { Page } from '../../../components';
// import { Page, ShopSectionHeader } from '../../../components';
import Hero from './Hero';
import Impact from './Impact';
// import Shop from './Shop';
import Mindset from './topics/Mindset';
import Entrepreneurship from './topics/Entrepreneurship';
import Skillset from './topics/Skillset';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%'
  },
  heroSection: {
    backgroundColor: theme.palette.vanscend.slate
  },
  section: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(8, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  compact: {
    maxWidth: 1140
  },
  sectionOver: {
    position: 'relative',
    zIndex: 1
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5)
    },
    maxWidth: 'none'
  },
  matrixBg: {
    background: theme.palette.vanscend.slate,
    width: '100%',
    height: '100%',
    '& $section': {
      paddingTop: '5vh',
      '&:first-child': {
        [theme.breakpoints.down('sm')]: {
          paddingTop: '2vh'
        },
        paddingTop: '10vh'
      },
      '&:last-child': {
        paddingBottom: '10vh'
      }
    }
  },
  matrixOverlay: {
    [theme.breakpoints.up('sm')]: {
      background: `linear-gradient(${theme.palette.vanscend.black}FF, 10%, ${theme.palette.vanscend.black}99)`,
      width: '100%',
      height: '100%'
    }
  },
  sectionWithSolidBg: {
    background: theme.palette.vanscend.green,
    position: 'absolute',
    top: 0,
    height: '40vh',
    width: '100%',
    clipPath: 'polygon(0 0, 100% 0, 100% 70%, 0 100%)',
    zIndex: -1
  },
  sectionWithBg: {
    position: 'relative'
  },
  sectionNoPaddingTop: {
    paddingTop: 0
  },
  shape: {
    paddingTop: theme.spacing(2),
    background: `linear-gradient(-45deg, ${theme.palette.vanscend.lightBlue} 15%, ${theme.palette.vanscend.orange})`,
    borderBottomRightRadius: '50%'
  },
  earlyAccess: {
    background: theme.palette.vanscend.green,
    boxShadow: theme.shadows[8],
    padding: theme.spacing(2, 2),
    position: 'sticky',
    zIndex: 999,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 2),
      top: 206
    },
    [theme.breakpoints.down('lg')]: {
      top: 172
    },
    [theme.breakpoints.down('md')]: {
      top: 147
    },
    [theme.breakpoints.down('sm')]: {
      top: 132
    },
    [theme.breakpoints.down('xs')]: {
      top: 104
    }
  },
  tagline: {
    position: 'sticky',
    top: 56,
    zIndex: 999,
    background: theme.palette.vanscend.slate,
    textAlign: 'center',
    color: theme.palette.vanscend.white,
    overflow: 'hidden',
    padding: theme.spacing(2, 2),
    fontSize: '5vw',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 2),
      top: 64
    }
  },
  callout: {
    color: theme.palette.vanscend.green
  }
}));

const Home = () => {
  const classes = useStyles();

  return (
    <Page title="Home" className={classes.root}>
      <Hidden smDown>
        <div className={classes.heroSection}>
          <section className={clsx(classes.section, classes.pagePaddingTop)}>
            <Hero />
          </section>
        </div>
      </Hidden>
      <Typography variant="h1" className={classes.tagline}>
        Making <span className={classes.callout}>Entrepreneurship</span> Work{' '}
        <span className={classes.callout}>For You</span>
      </Typography>
      {/* <section className={classes.earlyAccess}>
        <EarlyAccess />
      </section> */}
      <div className={classes.matrixBg}>
        <section className={clsx(classes.section, classes.sectionOver)}>
          <Entrepreneurship />
        </section>
        <section className={clsx(classes.section, classes.sectionOver)}>
          <Mindset />
        </section>
        <section className={clsx(classes.section, classes.sectionOver)}>
          <Skillset />
        </section>
      </div>
      <section className={classes.section}>
        <Impact />
      </section>
      {/* <section className={classes.sectionWithBg}>
        <div className={classes.sectionWithSolidBg} />
        <div className={clsx(classes.section, classes.compact)}>
          <ShopSectionHeader />
          <Shop />
        </div>
      </section> */}
    </Page>
  );
};

export default Home;
