import { API, Auth, Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { handleError, handleResponse } from './apiUtils';

const baseUrl = process.env.REACT_APP_PROFILE_API_URL;

export const getProfile = async (profileId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.get('profile', `/profile/${profileId}`, options);
};

export const updateProfile = async (
  profileId,
  firstName,
  lastName,
  userName,
  rate,
  avatar
) => {
  try {
    const auth = await (await Auth.currentSession()).getIdToken().getJwtToken();
    let response = await fetch(`${baseUrl}/profile/${profileId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${auth}`
      },
      body: JSON.stringify({ rate, firstName, lastName, userName, avatar })
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const changeProfileAvatar = async (profileId, file) => {
  try {
    let fileName = `${uuidv4()}-${file.name}`;
    const stored = await Storage.put(fileName, file, {
      contentType: file.type
    });
    fileName = process.env.REACT_APP_AVATAR_URI + stored.key;
    const auth = await (await Auth.currentSession()).getIdToken().getJwtToken();
    await fetch(`${baseUrl}/profile/${profileId}/updateAvatar`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${auth}`
      },
      body: JSON.stringify({ fileName })
    });
    return stored.key;
  } catch (error) {
    return handleError(error);
  }
};

export const watchVideo = async (profileId, videoId, lengthWatched) => {
  try {
    const auth = await (await Auth.currentSession()).getIdToken().getJwtToken();
    let response = await fetch(`${baseUrl}/profile/${profileId}/updateVideo`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${auth}`
      },
      body: JSON.stringify({ videoId, lengthWatched })
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const answerVideo = async (profileId, videoId, lengthWatched, qa) => {
  try {
    const auth = await (await Auth.currentSession()).getIdToken().getJwtToken();
    let response = await fetch(`${baseUrl}/profile/${profileId}/updateVideo`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${auth}`
      },
      body: JSON.stringify({ videoId, lengthWatched, qa })
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const addVideo = async (profileId, video) => {
  try {
    const auth = await (await Auth.currentSession()).getIdToken().getJwtToken();
    let response = await fetch(`${baseUrl}/profile/${profileId}/addVideo`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${auth}`
      },
      body: JSON.stringify({ videoId: video.id })
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};
