import { createSlice } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {},
  reducers: {
    getProfile: (_state, action) => {
      const { profile } = action.payload;
      return profile;
    },
    clearProfile: () => {
      return {};
    },
    updateProfile: (profile, action) => {
      const { firstName, lastName, userName, rate, avatar } = action.payload;

      if (rate || rate != profile.rate) {
        let profileValue = 0;
        profile.rate = rate;
        profile.videos.forEach((profileVideo) => {
          if (profileVideo.completed) {
            const secondRate = rate / 60 / 60;
            let videoLength = parseFloat(profileVideo.video.length);
            videoLength += profileVideo.qa.length ? 600 : 0;
            profileValue += videoLength * secondRate + 350;
          }
        });
        profile.value = profileValue.toFixed(2);
      }

      profile.firstName = firstName ?? profile.firstName;
      profile.lastName = lastName ?? profile.lastName;
      profile.userName = userName ?? profile.userName;
      profile.avatar = avatar ?? profile.avatar;

      return profile;
    },
    updateAvatar: (profile, action) => {
      const { fileName } = action.payload;

      profile.avatar = fileName;

      return profile;
    },
    watchVideo: (profile, action) => {
      const { videoId, length, duration } = action.payload;
      const watched = Math.floor(duration) - Math.floor(length) <= 20;
      const profileVideo = profile.videos.find(
        (profileVideo) => profileVideo.video.id == videoId
      );

      profileVideo.lengthWatched = watched ? duration : length;

      if (!profileVideo.watched && watched) {
        profileVideo.watched = true;
      }

      if (
        !profileVideo.completed &&
        profileVideo.watched &&
        !profileVideo.qa.length
      ) {
        const profileValue = parseFloat(profile.value);
        const value =
          Math.floor(duration) * (parseFloat(profile.rate) / 60 / 60) + 350;
        profileVideo.completed = true;
        profile.value = (profileValue + value).toFixed(2);
      }

      return profile;
    },
    addVideo: (profile, action) => {
      const { video } = action.payload;
      const qa = video.questions.map((videoQuestion) => {
        return { question: videoQuestion, answer: '' };
      });
      const newVideo = {
        video: { id: video.id, length: video.length },
        lengthWatched: 0,
        completed: false,
        watched: false,
        qa
      };
      profile.videos = [...profile.videos, newVideo];
      return profile;
    },
    answerVideo: (profile, action) => {
      const { videoId, qa } = action.payload;
      const profileVideo = profile.videos.find(
        (profileVideo) => profileVideo.video.id === videoId
      );

      let incomplete = false;
      for (const [key, value] of Object.entries(qa)) {
        const profileVideoQA = profileVideo.qa.find(
          (profileVideoQA) => profileVideoQA.question === key
        );
        profileVideoQA.answer = value;
        if (!value) {
          incomplete = true;
        }
      }

      if (!profileVideo.completed && !incomplete) {
        const profileValue = profile.value;
        const value =
          (parseFloat(profileVideo.video.length) + 600) *
            (parseFloat(profile.rate) / 60 / 60) +
          350;
        profileVideo.completed = true;
        profile.value = (profileValue + value).toFixed(2);
      }

      return profile;
    }
  }
});

const { actions, reducer } = profileSlice;

export const {
  getProfile,
  clearProfile,
  updateProfile,
  updateAvatar,
  watchVideo,
  addVideo,
  answerVideo
} = actions;

export default reducer;
