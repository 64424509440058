import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  pentagon: {
    background: theme.palette.vanscend.green,
    position: 'relative',
    top: 0,
    height: '10em',
    width: '100%',
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 75% 70%, 0 100%)',
    zIndex: 0
  },
  sectionHeader: {
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4)
    }
  },
  sectionTitle: {
    fontWeight: 'bold',
    position: 'absolute',
    top: '1.5em',
    left: 100,
    color: theme.palette.vanscend.white,
    filter: 'drop-shadow(0 0 2px #000)',
    fontSize: '4rem',
    [theme.breakpoints.down('sm')]: {
      left: 50
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3rem',
      top: '2em'
    }
  }
}));

const Header = ({ title }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.pentagon} />
      <div className={classes.sectionHeader}>
        <Typography
          variant="h1"
          align="center"
          className={classes.sectionTitle}
        >
          {title}
        </Typography>
      </div>
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string
};

export default Header;
