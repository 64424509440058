import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery, colors } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 50px 0 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 0 20px'
    }
  },
  image: {
    display: 'block'
  },
  sectionHeader: {
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2)
    }
  },
  sectionSubTitle: {
    filter: 'drop-shadow(0 0 5px #000)',
    color: theme.palette.vanscend.white,
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem'
    }
  },
  sectionTitle: {
    fontWeight: 'bold',
    filter: 'drop-shadow(0 0 5px #000)',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.25rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem'
    }
  },
  descriptive: {
    filter: 'drop-shadow(0 0 5px #000)',
    color: colors.grey[200],
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));

const Mindset = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });

  return (
    <div className={classes.root}>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sm={12}
          md={6}
          spacing={2}
          data-aos="fade-up"
        >
          <Grid item className={classes.sectionHeader}>
            <Typography
              variant="h1"
              align="left"
              className={classes.sectionSubTitle}
              color="textPrimary"
            >
              A nonprofit learning platform
            </Typography>
            <Typography
              variant="h1"
              color="primary"
              className={classes.sectionTitle}
            >
              That Exists Solely for its Members
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'h5'} align={'left'} color={'textSecondary'}>
              <span className={classes.descriptive}>
                Vanscend was created as a nonprofit to remain pure and insulated
                from outside interests. As a result Vanscend never has to
                generate revenue. The nonprofit status allows Vanscend&apos;s
                members to receive valuable lessons that are truly free.
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6} data-aos="fade-up">
          <img
            src="/static/images/hero2.png"
            alt="man speaking to group"
            className={classes.image}
            data-aos="fade-up"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Mindset;
