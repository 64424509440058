import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { Alert as MuiAlert } from '@material-ui/lab';
import {
  makeStyles,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Snackbar
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2)
    }
  },
  question: {
    fontSize: '1.15rem',
    fontWeight: 'bold'
  },
  action: {
    padding: theme.spacing(2)
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const VideoQuestions = ({ questions, submitQA }) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [submitting, isSubmitting] = useState(false);

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const questionsFormik = useFormik({
    initialValues: {},
    onSubmit: async (values, { setErrors }) => {
      isSubmitting(true);
      try {
        await submitQA(values);
        setOpenSnackbar(true);
      } catch (err) {
        setErrors({ afterSubmit: err });
      } finally {
        isSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (!loaded && questions && questionsFormik) {
      questions.map((question) => {
        questionsFormik.setFieldValue(question.question, question.answer);
      });
      setLoaded(true);
    }
  }, [loaded, questionsFormik, questions]);

  return (
    <Box width="100%" padding={4}>
      {!questions ? (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Typography variant="h5">Lesson Questions</Typography>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
          >
            <MuiAlert
              onClose={handleCloseSnackbar}
              severity="success"
              elevation={6}
              variant="filled"
            >
              Saved Successfully!
            </MuiAlert>
          </Snackbar>
          <FormikProvider value={questionsFormik}>
            <Form
              onSubmit={questionsFormik.handleSubmit}
              className={classes.root}
            >
              {questions.map((question, key) => (
                <Box key={key}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    className={classes.question}
                    gutterBottom
                  >
                    {question.question}
                  </Typography>
                  <TextField
                    id={question.question}
                    name={question.question}
                    fullWidth
                    value={questionsFormik.values[question.question]}
                    multiline
                    rows={4}
                    variant="outlined"
                    onChange={questionsFormik.handleChange}
                  />
                </Box>
              ))}
              <Grid
                item
                container
                justifyContent="flex-end"
                className={classes.action}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                >
                  Save
                </Button>
              </Grid>
            </Form>
          </FormikProvider>
        </>
      )}
    </Box>
  );
};

VideoQuestions.propTypes = {
  submitQA: PropTypes.func.isRequired,
  questions: PropTypes.array
};

export default VideoQuestions;
