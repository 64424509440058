import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  makeStyles
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  root: {},
  cardActions: {
    padding: theme.spacing(2)
  }
}));

const Password = ({ formik }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardHeader subheader="Update password" title="Password" />
          <Divider />
          <CardContent>
            <TextField
              fullWidth
              label="Password"
              margin="normal"
              name="password"
              {...getFieldProps('password')}
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={handleShowPassword} edge="end">
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <TextField
              fullWidth
              label="Confirm password"
              margin="normal"
              name="passwordConfirmation"
              {...getFieldProps('passwordConfirmation')}
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              error={Boolean(
                touched.passwordConfirmation && errors.passwordConfirmation
              )}
              helperText={
                touched.passwordConfirmation && errors.passwordConfirmation
              }
            />
          </CardContent>
          <Divider />
          <Grid
            item
            container
            justifyContent="flex-end"
            className={classes.cardActions}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              type="submit"
            >
              Update
            </Button>
          </Grid>
        </Card>
      </Form>
    </FormikProvider>
  );
};

Password.propTypes = {
  formik: PropTypes.object.isRequired
};

export default Password;
