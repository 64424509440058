import { createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import * as profileApi from '../../api/profileApi';
import { getProfile, clearProfile } from '../slices/profileSlice';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    userId: null,
    accountId: null
  },
  reducers: {
    userHasAuthenticated: (state, action) => {
      const auth = action.payload;
      state.isAuthenticated = auth.isAuthenticated;
      state.userId = auth.userId;
      state.accountId = auth.accountId;
    }
  }
});

export const login = (credentials) => {
  return async (dispatch) => {
    const user = await Auth.signIn(credentials.email, credentials.password);
    try {
      const profile = await profileApi.getProfile(user.username);
      dispatch(getProfile({ profile }));
      dispatch(
        authSlice.actions.userHasAuthenticated({
          isAuthenticated: true,
          userId: user.username,
          accountId: user.attributes.email
        })
      );
    } catch (err) {
      await Auth.signOut();
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    await Auth.signOut();
    dispatch(
      authSlice.actions.userHasAuthenticated({
        isAuthenticated: false,
        userId: null,
        accountId: null
      })
    );
    dispatch(clearProfile());
  };
};

export const checkAuth = () => {
  return async (dispatch) => {
    try {
      const session = await Auth.currentSession();
      const profile = await profileApi.getProfile(
        session.accessToken.payload.username
      );
      dispatch(getProfile({ profile }));
      dispatch(
        authSlice.actions.userHasAuthenticated({
          isAuthenticated: true,
          userId: session.accessToken.payload.username,
          accountId: session.idToken.payload.email
        })
      );
    } catch (err) {
      dispatch(
        authSlice.actions.userHasAuthenticated({
          isAuthenticated: false,
          userId: null,
          accountId: null
        })
      );
    }
  };
};

export default authSlice.reducer;
