import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';

import useStyles from '../styles';

const NameForm = ({ formik }) => {
  const classes = useStyles();
  const { errors, touched, getFieldProps } = formik;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      className={classes.formContent}
      spacing={3}
    >
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="First name"
          {...getFieldProps('firstName')}
          error={Boolean(touched.firstName && errors.firstName)}
          helperText={touched.firstName && errors.firstName}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Last name"
          {...getFieldProps('lastName')}
          error={Boolean(touched.lastName && errors.lastName)}
          helperText={touched.lastName && errors.lastName}
        />
      </Grid>
    </Grid>
  );
};

NameForm.propTypes = {
  formik: PropTypes.object.isRequired
};

export default NameForm;
