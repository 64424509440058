import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { ScrollToTop } from 'react-router-scroll-to-top';
import AOS from 'aos';
import { checkAuth } from './redux';
import theme, { GlobalStyles } from './theme';
import routes from './routes';
import { Spinner } from './components';
import 'aos/dist/aos.css';

const App = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const dispatch = useDispatch();
  const routing = useRoutes(routes);

  AOS.init();

  const onLoad = useCallback(async () => {
    await dispatch(checkAuth());
    setIsAuthenticating(false);
  }, [dispatch]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return isAuthenticating ? (
    <Spinner />
  ) : (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ScrollToTop />
      {routing}
    </ThemeProvider>
  );
};

export default App;
