import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import NumberFormat from 'react-number-format';

export const formatCurrency = (number) => {
  return numeral(number).format('$0,0.00');
};

export const FormatCurrencyInput = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
};

FormatCurrencyInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
