import { handleResponse, handleError } from './apiUtils';

const baseUrl = process.env.REACT_APP_SUPPORT_API_URL;

export const createCheckoutPage = async (cartItems, donation) => {
  try {
    let response = await fetch(`${baseUrl}/createCheckout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ cartItems, donation })
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};
