import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  colors,
  makeStyles,
  Box,
  Link,
  Hidden,
  Container,
  Typography
} from '@material-ui/core';
import SideSection from './SideSection';
import LoginForm from './LoginForm';
import { LogoInverse, Page } from '../../../components';
import { login } from '../../../redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.vanscend.white,
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  header: {
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      padding: theme.spacing(7, 5, 0, 7)
    }
  },
  content: {
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
  },
  link: {
    color: theme.palette.secondary.main
  },
  subTitle: {
    color: colors.grey[600]
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await dispatch(login(values));
        setSubmitting(false);
        navigate('/my-vanscend/learning');
      } catch (err) {
        setSubmitting(false);
        if (err.code === 'UserNotConfirmedException') {
          navigate(`/auth/verify?email=${values.email}`);
        } else {
          setErrors({ afterSubmit: err });
        }
      }
    }
  });

  return (
    <Page title="Login" className={classes.root}>
      <header className={classes.header}>
        <RouterLink to="/">
          <LogoInverse />
        </RouterLink>
        <Hidden smDown>
          <Box style={{ marginTop: -2 }}>
            <Typography variant="body2">
              Don’t have an account? &nbsp;
              <Link
                underline="none"
                variant="subtitle2"
                className={classes.link}
                component={RouterLink}
                to="/auth/register"
              >
                Get started
              </Link>
            </Typography>
          </Box>
        </Hidden>
      </header>

      <Hidden mdDown>
        <SideSection />
      </Hidden>

      <Container>
        <div className={classes.content}>
          <Box style={{ marginBottom: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in to Vanscend
            </Typography>
            <Typography className={classes.subTitle}>
              Enter your login details below.
            </Typography>
          </Box>

          <LoginForm formik={formik} />

          <Hidden smUp>
            <Box>
              <Typography
                variant="body2"
                style={{ marginTop: 5, textAlign: 'center' }}
              >
                Don’t have an account?&nbsp;
                <Link
                  variant="subtitle2"
                  to="/auth/register"
                  component={RouterLink}
                  className={classes.link}
                >
                  Get started
                </Link>
              </Typography>
            </Box>
          </Hidden>
        </div>
      </Container>
    </Page>
  );
};

export default LoginView;
