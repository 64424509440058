import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const AuthorizedRoute = ({ children }) => {
  const auth = useSelector((state) => state.auth);

  return auth.isAuthenticated ? children : <Navigate to="/" replace />;
};

AuthorizedRoute.propTypes = {
  children: PropTypes.object.isRequired
};

export default AuthorizedRoute;
