import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Grid, makeStyles } from '@material-ui/core';
import { Page } from '../../../../components';
import Notifications from './Notifications';
import Password from './Password';

const useStyles = makeStyles((theme) => ({
  root: {},
  section: {
    minHeight: '100%',
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(3),
    borderRadius: 8
  }
}));

const Settings = () => {
  const classes = useStyles();

  const notificationsFormik = useFormik({
    initialValues: {
      notifications: {
        suggestedVideos: true,
        newLearningPaths: true,
        newsLetter: false
      },
      messages: {
        email: true,
        textMessage: false
      }
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        setSubmitting(false);
      } catch (err) {
        setSubmitting(false);
        setErrors({ afterSubmit: err });
      }
    }
  });

  const PasswordSchema = Yup.object({
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    )
  });

  const passwordFormik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: PasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        setSubmitting(false);
      } catch (err) {
        setSubmitting(false);
        setErrors({ afterSubmit: err });
      }
    }
  });

  return (
    <Page title="Settings" className={classes.root}>
      <div className={classes.section}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Notifications formik={notificationsFormik} />
          </Grid>
          <Grid item xs={12}>
            <Password formik={passwordFormik} />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default Settings;
