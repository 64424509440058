import React, { useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import { Logo, Page } from '../../../components';
import ProspectForm from './ProspectForm';
import EarlyAdopter from './EarlyAdopter';
import { isEarlyAdopter, submitContact } from '../../../api/contactApi';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.vanscend.black,
    color: theme.palette.background.paper
  },
  header: {
    width: '100%',
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 13, 0)
    }
  },
  logo: {
    margin: '0 auto',
    height: 120,
    width: 500
  },
  tag: {
    margin: '0 auto',
    marginTop: 50,
    textAlign: 'center',
    fontSize: '1.4em',
    fontWeight: 'bold',
    letterSpacing: '0.025em'
  },
  description: {
    marginTop: 40,
    fontSize: '1.5em',
    '&:last-of-type': {
      marginBottom: 30
    }
  },
  callout: {
    color: theme.palette.vanscend.green
  },
  socialLink: {
    color: theme.palette.vanscend.orange,
    whiteSpace: 'nowrap',
    textDecoration: 'underline'
  },
  info: {
    marginTop: 100,
    [theme.breakpoints.down('md')]: {
      marginTop: 10
    },
    marginLeft: 25,
    marginRight: 25,
    marginBottom: 10
  },
  ucBlock: {
    marginTop: 50
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const SplashPage = () => {
  const classes = useStyles();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const getEarlyAdopterStatus = useCallback(async () => {
    try {
      await isEarlyAdopter();
    } catch (error) {
      console.warn(error.message);
    }
  }, []);

  useEffect(() => {
    getEarlyAdopterStatus();
  }, [getEarlyAdopterStatus]);

  const ProspectSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    name: Yup.string().required('Name is required'),
    zipCode: Yup.string()
      .matches(/^[0-9]+$/, 'Must use digits only')
      .min(5, 'Must use 5 digit zip code')
      .max(5, 'Must use 5 digit zip code'),
    comment: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      zipCode: '',
      comment: ''
    },
    validationSchema: ProspectSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await submitContact(values);
        resetForm();
        setHasSubmitted(true);
      } catch (err) {
        setSubmitting(false);
        setErrors({ afterSubmit: err });
      }
    }
  });

  return (
    <Page title="Landing" className={classes.root}>
      <header className={classes.header}>
        <Logo className={classes.logo} />
      </header>
      <Container>
        <Typography className={classes.tag}>
          Advancing the{' '}
          <span className={classes.callout} style={{ fontStyle: 'italic' }}>
            entrepreneurial, employment and education
          </span>{' '}
          interests of people in recovery
        </Typography>
        <Typography className={classes.description}>
          <span className={classes.callout}>Vanscend</span> is a nonprofit that
          makes its program free to anyone who joins. The Vanscend program is
          led by experienced volunteers who want to give back.{' '}
          <a
            className={classes.socialLink}
            href="https://www.linkedin.com/pulse/vanscend-secures-grant-hiring-dave-garvey"
            target="_blank"
            rel="noreferrer"
          >
            Read more about it here
          </a>
          .
        </Typography>
        <Typography className={classes.description}>
          <span className={classes.callout}>Vanscend</span> works best when
          people are actively committed to their recovery. Sign up below to
          learn more about free in-person Vanscend workshops – and to gain
          access to the free online learning platform when it launches later
          this year (your information is not shared or sold – part of the reason
          why we are a nonprofit)
        </Typography>
        {hasSubmitted ? (
          <EarlyAdopter setHasSubmitted={setHasSubmitted} />
        ) : (
          <ProspectForm formik={formik} />
        )}
      </Container>
    </Page>
  );
};

export default SplashPage;
