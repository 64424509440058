import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Link,
  Hidden,
  Container,
  Typography
} from '@material-ui/core';
import { Page, LogoInverse } from '../../../components';
import RegisterForm from './RegisterForm';
import SideSection from './SideSection';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.vanscend.white,
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  header: {
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      padding: theme.spacing(7, 5, 0, 7)
    }
  },
  content: {
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
  },
  link: {
    color: theme.palette.secondary.main
  }
}));

const RegisterView = () => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await Auth.signUp({
          username: values.email,
          password: values.password
        });
        setSubmitting(false);
        setSuccess(true);
      } catch (err) {
        setErrors({ afterSubmit: err });
        setSubmitting(false);
      }
    }
  });

  return (
    <Page title="Register" className={classes.root}>
      <header className={classes.header}>
        <RouterLink to="/">
          <LogoInverse />
        </RouterLink>
        {!success && (
          <Hidden smDown>
            <Box style={{ marginTop: -2 }}>
              <Typography variant="body2">
                Already have an account? &nbsp;
                <Link
                  underline="none"
                  variant="subtitle2"
                  component={RouterLink}
                  to="/auth/login"
                  className={classes.link}
                >
                  Login
                </Link>
              </Typography>
            </Box>
          </Hidden>
        )}
      </header>

      <Hidden mdDown>
        <SideSection />
      </Hidden>

      <Container>
        <div className={classes.content}>
          {!success ? (
            <>
              <Box style={{ marginBottom: 5 }}>
                <Typography variant="h4" gutterBottom>
                  Get started absolutely free.
                </Typography>
              </Box>

              <RegisterForm formik={formik} />

              <Box style={{ marginTop: 5 }}>
                <Typography
                  variant="body2"
                  align="center"
                  color="textSecondary"
                >
                  By registering, I agree to Vanscend&apos;s&nbsp;
                  <Link color="textPrimary" underline="always">
                    Terms of Service
                  </Link>
                  &nbsp;and&nbsp;
                  <Link color="textPrimary" underline="always">
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </Box>

              <Hidden smUp>
                <Box style={{ marginTop: 10 }}>
                  <Typography variant="body2" align="center">
                    Already have an account?&nbsp;
                    <Link
                      variant="subtitle2"
                      to="/auth/login"
                      component={RouterLink}
                    >
                      Login
                    </Link>
                  </Typography>
                </Box>
              </Hidden>
            </>
          ) : (
            <Box style={{ textAlign: 'center' }}>
              <Box style={{ marginBottom: 10 }}>
                <Box
                  component="img"
                  alt="sent email"
                  height={100}
                  src="/static/illustrations/illustration_email_sent.svg"
                  style={{ marginBottom: 10, margin: 'auto' }}
                />
                <Typography variant="h3" gutterBottom>
                  Registration Successful!
                </Typography>
                <Typography>
                  We have sent a confirmation email to &nbsp;
                  <strong>{formik.values.email}</strong>
                  <br />
                  Please check your email.
                </Typography>
              </Box>
              <Button
                size="large"
                variant="contained"
                component={RouterLink}
                to="/auth/login"
                className={classes.link}
              >
                Back
              </Button>
            </Box>
          )}
        </div>
      </Container>
    </Page>
  );
};

export default RegisterView;
