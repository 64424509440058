import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  makeStyles,
  Box,
  Card,
  Typography,
  Grid,
  Avatar,
  IconButton,
  Tooltip
} from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { formatCurrency } from '../utils';
import { AvatarGroup } from '@material-ui/lab';
import { addToCart } from '../redux';
import { ProductQtyLabel } from '../components';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: theme.shadows[10],
    borderRadius: 16,
    position: 'relative',
    zIndex: 0
  },
  image: {
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
  },
  sizeGroup: {
    padding: '.25em',
    marginTop: -45,
    marginLeft: 'auto'
  },
  size: {
    color: theme.palette.vanscend.green,
    background: theme.palette.background.light,
    '&:hover': {
      background: theme.palette.vanscend.green,
      color: theme.palette.vanscend.black
    }
  },
  selectedSize: {
    background: theme.palette.vanscend.green,
    color: theme.palette.vanscend.black,
    '&:hover': {
      background: theme.palette.vanscend.green,
      color: theme.palette.vanscend.black
    }
  }
}));

const ShopProductCard = ({ product, products, handleOpenCart }) => {
  const classes = useStyles();
  const [selectedSize, setSelectedSize] = useState();
  const [storeProduct, setStoreProduct] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name, img, cost } = product;

  const sortedSizes = useMemo(() => {
    if (product.sizes && product.sizes.length > 0) {
      const order = ['s', 'm', 'l', 'xl', 'xxl'];
      const res = product.sizes.sort((a, b) => {
        a = a.toLowerCase();
        b = b.toLowerCase();

        let nra = parseInt(a);
        let nrb = parseInt(b);

        if (order.indexOf(a) != -1) nra = NaN;
        if (order.indexOf(b) != -1) nrb = NaN;

        if (nrb === 0) return 1;
        if ((nra && !nrb) || nra === 0) return -1;
        if (!nra && nrb) return 1;
        if (nra && nrb) {
          if (nra == nrb) {
            return a
              .substr(('' + nra).length)
              .localeCompare(a.substr(('' + nra).length));
          } else {
            return nra - nrb;
          }
        } else {
          return order.indexOf(a) - order.indexOf(b);
        }
      });
      return res;
    } else {
      return null;
    }
  }, [product.sizes]);

  useEffect(() => {
    let item = null;
    if (products.length > 0) {
      if (sortedSizes) {
        if (selectedSize) {
          item = products.find(
            (p) =>
              p.name.toLowerCase() === product.name.toLowerCase() &&
              p.size.toLowerCase() === selectedSize.toLowerCase()
          );
        }
      } else {
        item = products.find(
          (p) => p.name.toLowerCase() === product.name.toLowerCase()
        );
      }
    }
    setStoreProduct(item);
  }, [product.name, products, selectedSize, sortedSizes]);

  const handleAddProduct = async () => {
    if (storeProduct) {
      if (sortedSizes && selectedSize) {
        try {
          await dispatch(addToCart({ product: storeProduct }));
          if (handleOpenCart) {
            handleOpenCart();
          } else {
            navigate('/support?redirect=true');
          }
        } catch (err) {
          alert(err.message);
        }
      } else {
        try {
          await dispatch(addToCart({ product: storeProduct }));
          if (handleOpenCart) {
            handleOpenCart();
          } else {
            navigate('/support?redirect=true');
          }
        } catch (err) {
          alert(err.message);
        }
      }
    }
  };

  const handleSelectSize = (size) => {
    setSelectedSize(size);
  };
  return (
    <Card className={classes.card}>
      <Box style={{ paddingTop: '100%', position: 'relative' }}>
        {storeProduct && storeProduct.stock <= 0 ? (
          <ProductQtyLabel
            variant="backordered"
            style={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            Backordered
          </ProductQtyLabel>
        ) : storeProduct && storeProduct.stock <= 5 ? (
          <ProductQtyLabel
            variant="lowStock"
            style={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {`Only ${storeProduct.stock} Left!`}
          </ProductQtyLabel>
        ) : (
          ''
        )}
        <img alt={name} src={img} className={classes.image} />
      </Box>

      <Grid container direction="column" spacing={2} style={{ padding: 20 }}>
        {sortedSizes && (
          <AvatarGroup className={classes.sizeGroup}>
            {sortedSizes.map((size) => (
              <Avatar
                key={size}
                className={
                  selectedSize &&
                  size.toLowerCase() === selectedSize.toLowerCase()
                    ? classes.selectedSize
                    : classes.size
                }
                component={IconButton}
                color="primary"
                onClick={() => handleSelectSize(size)}
              >
                {size}
              </Avatar>
            ))}
          </AvatarGroup>
        )}

        <Typography noWrap>{name}</Typography>

        <Grid container alignItems="center" justifyContent="space-between">
          {sortedSizes ? (
            <Tooltip
              title="Please select a size"
              disableFocusListener={sortedSizes && selectedSize ? true : false}
              disableHoverListener={sortedSizes && selectedSize ? true : false}
              disableTouchListener={sortedSizes && selectedSize ? true : false}
            >
              <IconButton color="primary" onClick={() => handleAddProduct()}>
                <AddShoppingCartIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton color="primary" onClick={() => handleAddProduct()}>
              <AddShoppingCartIcon />
            </IconButton>
          )}
          <Typography variant="subtitle1">{formatCurrency(cost)}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

ShopProductCard.propTypes = {
  product: PropTypes.object,
  products: PropTypes.array,
  handleOpenCart: PropTypes.func
};

export default ShopProductCard;
