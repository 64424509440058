import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Page, Header } from '../../../components';
import ContactForm from './ContactForm';
import { submitContact } from '../../../api/contactApi';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    textAlign: 'center',
    marginTop: 25
  },
  formWrapper: {
    height: '100%',
    marginTop: 50,
    marginBottom: 100,
    [theme.breakpoints.down('xs')]: {
      padding: '0 15px'
    }
  },
  formSection: {
    maxWidth: 750
  },
  image: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '55em'
  },
  title: {
    textAlign: 'center'
  },
  description: {
    textAlign: 'left',
    padding: theme.spacing(2, 2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  descItem: {
    display: 'block',
    padding: '5px 0',
    fontSize: '17px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  callout: {
    color: theme.palette.vanscend.blue,
    fontWeight: 'bold'
  }
}));

const Contact = () => {
  const classes = useStyles();

  const ContactSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    name: Yup.string().required('Name is required'),
    comment: Yup.string().required('You must have something to say')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      comment: ''
    },
    validationSchema: ContactSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await submitContact(values);
        resetForm();
      } catch (err) {
        setSubmitting(false);
        setErrors({ afterSubmit: err });
      }
    }
  });

  return (
    <Page title="Contact" className={classes.root}>
      <Header title="Get in touch" />
      <Grid container direction="row" className={classes.pagePaddingTop}>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className={classes.formWrapper}
          lg
        >
          <Grid item>
            <Grid item container className={classes.formSection}>
              <Grid item>
                <Typography variant="h2" className={classes.title}>
                  Leave a Message
                </Typography>
                <Typography variant="h6" className={classes.description}>
                  <span className={classes.descItem}>
                    We would <span className={classes.callout}>love</span> to
                    hear from you!!
                  </span>
                  <span className={classes.descItem}>
                    If you are a <span className={classes.callout}>member</span>{' '}
                    of Vanscend and have have a success story to share, then let
                    us know!
                  </span>
                  <span className={classes.descItem}>
                    If you are from a{' '}
                    <span className={classes.callout}>community</span> that
                    wants to bring Vanscend there, then let us know!
                  </span>
                  <span className={classes.descItem}>
                    If you are a{' '}
                    <span className={classes.callout}>potential partner</span>{' '}
                    and your values are aligned Vanscend, then let us know!
                  </span>
                  <span className={classes.descItem}>
                    If you want to support Vanscend in anyway,{' '}
                    <span className={classes.callout}>please</span> let us know!
                  </span>
                </Typography>
              </Grid>
              <ContactForm formik={formik} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Contact;
