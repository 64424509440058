import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Avatar,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import dayjs from 'dayjs';
import { updateProfileAvatar } from '../../../../redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 222,
    width: 222
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  inputContainer: {
    width: '100%'
  },
  input: {
    display: 'none'
  },
  userName: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem'
    }
  }
}));

const ProfileAvatar = ({ profile }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleProfilePictureUpload = async (event) => {
    if (event.target.files.length > 0) {
      await dispatch(updateProfileAvatar(profile.id, event.target.files[0]));
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Avatar src={profile.avatar} className={classes.avatar} />
            </Grid>
            <Grid item>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
                className={classes.userName}
              >
                {profile.userName ??
                  `${
                    profile.firstName
                      ? profile.firstName.charAt(0).toUpperCase()
                      : ''
                  } ${
                    profile.lastName
                      ? profile.lastName.charAt(0).toUpperCase()
                      : ''
                  }`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body1">
                Member Since: {dayjs(profile.createdAt).format('MMMM DD, YYYY')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <div className={classes.inputContainer}>
            <input
              accept="image/*"
              className={classes.input}
              id="upload-picture-button"
              type="file"
              onChange={handleProfilePictureUpload}
            />
            <label htmlFor="upload-picture-button">
              <Button color="primary" component="span" fullWidth>
                Upload picture
              </Button>
            </label>
          </div>
        </CardActions>
      </Card>
    </>
  );
};

ProfileAvatar.propTypes = {
  profile: PropTypes.object.isRequired
};

export default ProfileAvatar;
