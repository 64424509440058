import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery, colors } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 50px 0 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 0 20px'
    }
  },
  image: {
    display: 'block'
  },
  sectionHeader: {
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2)
    }
  },
  sectionSubTitle: {
    filter: 'drop-shadow(0 0 5px #000)',
    color: theme.palette.vanscend.white,
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
      display: 'inline-block'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem'
    }
  },
  sectionTitle: {
    fontWeight: 'bold',
    filter: 'drop-shadow(0 0 5px #000)',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.25rem',
      display: 'inline-block'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem'
    }
  },
  descriptive: {
    filter: 'drop-shadow(0 0 5px #000)',
    color: colors.grey[200],
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));

const Skillset = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });

  return (
    <div className={classes.root}>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item sm={12} md={12} data-aos="fade-up">
          <img
            src="/static/images/hero3.png"
            alt="man smiling in open van"
            className={classes.image}
            data-aos="fade-up"
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          sm={12}
          md={12}
          spacing={2}
          data-aos="fade-up"
        >
          <Grid item className={classes.sectionHeader}>
            <Typography
              variant="h1"
              align="left"
              className={classes.sectionSubTitle}
              color="textPrimary"
            >
              A social entrepreneur with&nbsp;
            </Typography>
            <Typography
              variant="h1"
              color="primary"
              className={classes.sectionTitle}
            >
              20+ years of experience
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'h5'} align={'left'} color={'textSecondary'}>
              <span className={classes.descriptive}>
                Over 20 years ago Vanscend&apos;s founder Dave Garvey discovered
                that entrepreneurship can give people purpose and it can save
                lives. Especially people from disadvantaged and underdog
                backgrounds &ndash; since they have advantages they don&apos;t
                realize. Dave&apos;s goal with Vanscend is to help as many of
                these people as possible with finding the right combination of
                entrepreneurship, business and life skills &ndash; so they find
                success &ndash; and can someday go on to pay it forward too.
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Skillset;
