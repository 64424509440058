import React from 'react';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';

const menuLinks = [
  { title: 'Impact', href: '/impact' },
  { title: 'About', href: '/about' },
  { title: 'Support', href: '/support' },
  { title: 'Contact', href: '/contact' },
  { title: 'Terms', href: '/terms' },
  { title: 'Privacy', href: '/privacy' }
];

const useStyles = makeStyles((theme) => ({
  footerRoot: {
    height: '50vh',
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
    backgroundColor: 'inherit',
    [theme.breakpoints.down('sm')]: {
      height: '70vh'
    },
    [theme.breakpoints.down('xs')]: {
      height: '50vh'
    }
  },
  tagLine: {
    color: theme.palette.vanscend.green,
    position: 'absolute',
    top: 120,
    lineHeight: '0.9em',
    fontFamily: 'Poppins',
    left: 250,
    zIndex: 1,
    [theme.breakpoints.down('xl')]: {
      top: '5.8vh',
      left: '6vw',
      fontSize: '4rem'
    },
    [theme.breakpoints.down('lg')]: {
      top: '6vh',
      left: '6vw',
      fontSize: '4rem'
    },
    [theme.breakpoints.down('md')]: {
      left: '5vw',
      top: '6vh',
      fontSize: '3.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
      left: '9vw',
      top: '6vh'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.5rem',
      left: '5vw',
      top: '9vh'
    }
  },
  callout: {
    color: theme.palette.vanscend.blue,
    letterSpacing: '-0.04em'
  },
  topPoly: {
    background: '#EBEBEB',
    position: 'relative',
    top: 0,
    height: '50vh',
    clipPath: 'polygon(0 0, 100% 20%, 100% 100%, 0 100%)'
  },
  bottomPoly: {
    background: theme.palette.vanscend.black,
    marginTop: '-35vh',
    height: '45vh',
    clipPath: 'polygon(0 15%, 25% 30%, 100% 0, 100% 100%, 0 100%)',
    [theme.breakpoints.down('sm')]: {
      height: '65vh'
    },
    [theme.breakpoints.down('xs')]: {
      height: '45vh'
    }
  },
  footerContainer: {
    position: 'absolute',
    left: 'calc(50% - 185px)',
    top: '60%',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      left: 'calc(50% - 175px)'
    }
  },
  logoContainer: {
    height: 80,
    [theme.breakpoints.down('xs')]: {
      marginTop: '-5vh',
      paddingTop: 25
    }
  },
  logoImage: {
    width: '25%',
    height: '60%',
    display: 'block',
    margin: '0 auto'
  },
  logoText: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 30,
    textAlign: 'center'
  },
  navList: {
    marginTop: '2em',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5em'
    }
  },
  navLink: {
    color: theme.palette.vanscend.white,
    marginLeft: 20,
    '&:first-child': {
      marginLeft: 0
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 16
    }
  },
  copyright: {
    color: theme.palette.vanscend.white,
    textAlign: 'center',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem'
    }
  }
}));

const MainFooter = () => {
  const classes = useStyles();

  const renderNavLinks = (
    <div className={classes.navList}>
      {menuLinks.map((link) => (
        <Link
          exact="true"
          to={link.href}
          key={link.title}
          color="initial"
          underline="none"
          variant="subtitle2"
          component={NavLink}
          className={classes.navLink}
        >
          {link.title}
        </Link>
      ))}
    </div>
  );

  return (
    <div className={classes.footerRoot}>
      <Typography variant="h1" className={classes.tagLine}>
        Vanscend
        <br />
        <span className={classes.callout}>empowers.</span>
      </Typography>
      <div className={classes.footerContainer}>
        <div className={classes.logoContainer}>
          <RouterLink to="/">
            <img
              className={classes.logoImage}
              src="/static/brand/companyLogoStamp.svg"
            />
            <div className={classes.logoText}>Vanscend</div>
          </RouterLink>
        </div>
        {renderNavLinks}
      </div>
      <div className={classes.topPoly} />
      <div className={classes.bottomPoly} />
      <Typography paragraph={false} className={classes.copyright}>
        © 2020-{new Date().getFullYear()} Vanscend - All rights reserved
      </Typography>
    </div>
  );
};

export default MainFooter;
