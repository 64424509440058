export const handleResponse = async (response) => {
  if (response.status === 204) return;
  if (response.ok) return response.json();
  const errorMessage = await response.text();
  if (response.status === 400) {
    throw new Error(errorMessage);
  }
  throw new Error(errorMessage);
};

export const handleError = (error) => {
  // eslint-disable-next-line no-console
  console.error('API call failed: ' + error);
  throw error;
};
