import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Logo } from '../../components';
import { logout } from '../../redux';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: {
    backgroundColor: theme.palette.vanscend.black,
    [theme.breakpoints.up('sm')]: {
      boxShadow: theme.shadows[3]
    }
  },
  iconBtn: {
    color: theme.palette.vanscend.white
  }
}));

const TopBar = ({ onMobileNavOpen }) => {
  const classes = useStyles();
  // const [notifications] = useState(['test']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      navigate('/');
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <AppBar
      color="transparent"
      elevation={0}
      className={clsx(classes.root, classes.appBar)}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          {/* <IconButton className={classes.iconBtn}>
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton className={classes.iconBtn} onClick={handleLogout}>
            <ExitToAppIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton className={classes.iconBtn} onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
