import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { loadVideos, addVideoToProfile } from '../../../../redux';
import { Page, VideoList, VideoGroup } from '../../../../components';

const useStyles = makeStyles((theme) => ({
  root: {},
  section: {
    minHeight: '100%',
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(3),
    borderRadius: 8
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  search: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1)
    }
  },
  mobileSearch: {
    marginTop: '4px',
    marginRight: '2px'
  }
}));

const Learning = () => {
  const videos = useSelector((state) => state.learning);
  const profile = useSelector((state) => state.profile);
  const location = useLocation();
  const clusterName = new URLSearchParams(location.search).get('clusterName');
  const [userVideos, setUserVideos] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredVideos, filterVideos] = useState(videos);
  const [videoClusterName, setVideoClusterName] = useState(clusterName);
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true
  });
  const [isFocused, setFocused] = useState(false);
  const showSearch = !isMobile || isFocused || (isMobile && search !== '');

  useEffect(() => {
    if (videos.length === 0) {
      dispatch(loadVideos());
    }
  }, [dispatch, videos]);

  useEffect(() => {
    filterVideos(videos);
  }, [videos]);

  useEffect(() => {
    setUserVideos(profile.videos);
  }, [profile]);

  const watchVideo = async (video, clusterName) => {
    setVideoClusterName(clusterName);
    if (!userVideos.find((userVideo) => userVideo.video.id === video.id)) {
      dispatch(addVideoToProfile(profile.id, video));
    }
  };

  const searchVideos = (value) => {
    setSearch(value);
    if (value === '') {
      setFocused(false);
    }
  };

  const applyFilter = useCallback(
    (filter) => {
      let results = videos;
      if (filter) {
        results = videos.filter(
          (video) =>
            video.name.toLowerCase().includes(filter.toLowerCase()) ||
            video.tags.some((tag) =>
              tag.toLowerCase().includes(filter.toLowerCase())
            )
        );
      }
      return results;
    },
    [videos]
  );

  useEffect(() => {
    if (search) {
      filterVideos(applyFilter(search));
    } else {
      filterVideos(videos);
    }
  }, [videos, search, applyFilter]);

  return (
    <Page title="Learning" className={classes.root}>
      <div className={classes.section}>
        <Card>
          <CardHeader
            className={classes.header}
            title={
              !isMobile || !showSearch ? (
                <Typography variant="h5">
                  <span>MyVanscend</span>
                </Typography>
              ) : (
                ''
              )
            }
            action={
              showSearch ? (
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  focused={isFocused}
                  onChange={(event) => searchVideos(event.target.value)}
                  className={classes.search}
                  InputProps={{
                    endAdornment: (
                      <SearchIcon onClick={() => setFocused(false)} />
                    )
                  }}
                />
              ) : (
                <IconButton
                  aria-label="search"
                  onClick={() => setFocused(true)}
                >
                  <SearchIcon className={classes.mobileSearch} />
                </IconButton>
              )
            }
          />
          <Divider className={classes.divider} />
          {search === '' ? (
            <VideoGroup
              videos={filteredVideos}
              userVideos={userVideos}
              watchVideo={watchVideo}
              expandedClusterName={videoClusterName}
            />
          ) : (
            <VideoList
              videos={filteredVideos}
              userVideos={userVideos}
              watchVideo={watchVideo}
            />
          )}
        </Card>
      </div>
    </Page>
  );
};

export default Learning;
