import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Container } from '@material-ui/core';
import { Page } from '../../../components';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh'
  }
}));

const PageNotFoundView = () => {
  const classes = useStyles();

  return (
    <Page title='404 Page Not Found' className={classes.root}>
      <Container>
        <Box style={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <img
            alt='404'
            src='/static/illustrations/illustration_404.svg'
            style={{ width: '100%', maxHeight: 240 }}
          />
          <Typography color='textSecondary' gutterBottom>
            Sorry, we couldn’t find the page you’re looking for.
          </Typography>
        </Box>
      </Container>
    </Page>
  );
};

export default PageNotFoundView;
