import React from 'react';
import { colors, makeStyles, Card, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(2, 0, 2, 2),
    boxShadow: theme.shadows[10],
    backgroundColor: theme.palette.vanscend.white
  },
  sectionTitle: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(5),
    color: colors.grey[600]
  }
}));

const SideSection = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Typography variant="h3" className={classes.sectionTitle}>
        What&apos;s your version of Vanscend?
      </Typography>
      <img
        alt="register"
        src="/static/illustrations/illustration_professor.svg"
      />
    </Card>
  );
};

export default SideSection;
