import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  makeStyles
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    color: theme.palette.vanscend.black
  }
}));

const ResetPasswordVerification = ({ formik }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <TextField
          fullWidth
          variant="outlined"
          {...getFieldProps('code')}
          type="text"
          label="Verification Code"
          error={
            Boolean(touched.code && errors.code) ||
            (errors.afterSubmit &&
              errors.afterSubmit.code === 'CodeMismatchException')
          }
          helperText={
            (touched.code && errors.code) ||
            (errors.afterSubmit &&
              errors.afterSubmit.code === 'CodeMismatchException' &&
              errors.afterSubmit.message)
          }
        />
        <Box sx={{ mb: 3 }} />
        <TextField
          style={{ marginTop: 10 }}
          fullWidth
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          {...getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
        />
        <Box sx={{ mb: 3 }} />
        <TextField
          style={{ marginTop: 10 }}
          fullWidth
          variant="outlined"
          type={showConfirmPassword ? 'text' : 'password'}
          label="Confirm Password"
          {...getFieldProps('confirmPassword')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
          helperText={touched.confirmPassword && errors.confirmPassword}
        />
        <Box style={{ marginTop: 5 }}>
          <Button
            className={classes.submitButton}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Reset Password
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};

ResetPasswordVerification.propTypes = {
  formik: PropTypes.object.isRequired
};

export default ResetPasswordVerification;
