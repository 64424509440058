import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button, Grid, Typography, MobileStepper } from '@material-ui/core';
import { API } from 'aws-amplify';

import NameForm from './Forms/NameForm';
import HandleForm from './Forms/HandleForm';
import InterestsForm from './Forms/InterestsForm';
import validationSchema from './FormModel/validationSchema';
import profileFormModel from './FormModel/profileFormModel';
import formInitialValues from './FormModel/formInitialValues';
import { Page } from '../../../components';
import useStyles from './styles';

const steps = ['Name', 'Handle', 'Interests'];
const { formId, formField } = profileFormModel;

const renderStepContent = (step, formik) => {
  switch (step) {
    case 0:
      return <NameForm formField={formField} formik={formik} />;
    case 1:
      return <HandleForm formField={formField} formik={formik} />;
    case 2:
      return <InterestsForm formField={formField} formik={formik} />;
    default:
      return <div>Oops... Nothing Here.</div>;
  }
};

const renderHeaderText = (step, formik) => {
  const { firstName, username } = formik.values;
  switch (step) {
    case 0:
      return 'Just a few things to get you started:';
    case 1:
      return `Hey ${firstName}, What would you like to be called online?`;
    case 2:
      return `Okay ${username}, what are you interested in learning more about?`;
  }
};

const saveProfile = async (profileData, userId, accountId) => {
  return API.patch('profile', `/profiles/${userId}`, {
    body: { ...profileData, email: accountId }
  });
};

const ProfilePageView = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [buttonsDisabled, disableButtons] = useState(false);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: currentValidationSchema,
    onSubmit: async (values, { setErrors, setSubmitting, setTouched }) => {
      try {
        if (isLastStep) {
          // send values to server
          await saveProfile(values, auth.userId, auth.accountId);
          navigate('/profile', true);
        } else {
          setActiveStep(activeStep + 1);
          setTouched({});
        }
        setSubmitting(false);
      } catch (err) {
        setErrors({ afterSubmit: err });
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    disableButtons(formik.errors.length > 0);
  }, [formik.errors]);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Page title="Profile Setup">
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.content}
      >
        <Grid item className={classes.formHeader}>
          <Typography variant="h1" className={classes.headerText}>
            Welcome to <span className={classes.callout}>Vanscend!</span>
          </Typography>
        </Grid>
        <Grid item className={classes.formDescription}>
          <Typography
            variant="h6"
            color="textSecondary"
            className={classes.descriptionText}
          >
            <span className={classes.formHeaderText}>
              {renderHeaderText(activeStep, formik)}
            </span>
          </Typography>
        </Grid>
        <FormikProvider value={formik}>
          <Form id={formId} onSubmit={formik.handleSubmit}>
            {renderStepContent(activeStep, formik)}
            <MobileStepper
              variant="dots"
              steps={3}
              position="static"
              activeStep={activeStep}
              className={classes.stepper}
              nextButton={
                <Button disabled={buttonsDisabled} size="small" type="submit">
                  {isLastStep ? 'Vanscend' : 'Next'}
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
            />
          </Form>
        </FormikProvider>
      </Grid>
    </Page>
  );
};

export default ProfilePageView;
