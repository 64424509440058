import { handleResponse, handleError } from './apiUtils';

const baseUrl = process.env.REACT_APP_PRODUCT_API_URL;

export const getProducts = async () => {
  try {
    let response = await fetch(`${baseUrl}/product`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};
