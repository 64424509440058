import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  makeStyles,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  Typography
} from '@material-ui/core';
import { Page, Header } from '../../../components';
import { loadProducts, clearCart } from '../../../redux';
// import ProductList from './ProductList';
import CartWidget from './ProductCartWidget';
import ShoppingCart from './ShoppingCart';
import Donate from './Donate';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: theme.palette.vanscend.black
  },
  banner: {
    minWidth: '100vw',
    marginTop: -85,
    [theme.breakpoints.down('sm')]: {
      marginTop: -50
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: -50
    }
  },
  shopGrid: {
    position: 'relative',
    marginTop: 0,
    zIndex: 1
  },
  sectionWithSolidBg: {
    backgroundColor: theme.palette.vanscend.black,
    position: 'absolute',
    marginTop: -265,
    width: '100vw',
    height: '400px',
    clipPath: 'polygon(0 30%, 100% 0, 100% 70%, 0 100%)',
    zIndex: -1,
    [theme.breakpoints.down('md')]: {
      marginTop: -210,
      height: '300px'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: -150,
      height: '275px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: -115,
      height: '290px'
    }
  },
  donateSection: {
    height: '210px',
    marginTop: -150,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: -85
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: -40
    }
  }
}));

const Support = () => {
  const classes = useStyles();
  const location = useLocation();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const donation = useSelector((state) => state.cart.donation);
  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const [openCart, setOpenCart] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const fetchProducts = useCallback(async () => {
    try {
      await dispatch(loadProducts());
    } catch (err) {
      alert(
        'There was an issue loading shop products. Please refresh the page.'
      );
    }
  }, [dispatch]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    const success = new URLSearchParams(location.search).get('success');
    if (success) {
      dispatch(clearCart());
      setShowConfirmation(true);
    }

    const cancelled = new URLSearchParams(location.search).get('cancel');
    if (cancelled) {
      setOpenCart(true);
    }

    const redirected = new URLSearchParams(location.search).get('redirect');
    if (redirected) {
      setOpenCart(true);
    }
  }, [dispatch, location]);

  const handleOpenCart = () => {
    setOpenCart(true);
  };

  const handleCloseCart = () => {
    setOpenCart(false);
  };

  const cartCount = useMemo(() => {
    let count = cartItems.reduce((acc, curr) => Number(acc + curr.qty), 0);
    if (donation > 0) {
      count = count + 1;
    }
    return count;
  }, [cartItems, donation]);

  return (
    <>
      <Page title="Shop" className={classes.root}>
        <Header title="Support" />
        <img
          className={classes.banner}
          src="/static/backgrounds/shop_banner.png"
        />
        <Grid container className={classes.shopGrid}>
          <div className={classes.sectionWithSolidBg} />
          <Donate
            className={classes.donateSection}
            isBgDark={true}
            handleOpenCart={handleOpenCart}
          />
          <Container>
            {/* <ProductList products={products} handleOpenCart={handleOpenCart} /> */}
            <CartWidget onOpenCart={handleOpenCart} cartCount={cartCount} />
          </Container>
        </Grid>
        {((products && products.length > 0) || donation > 0) && (
          <ShoppingCart
            isCartOpen={openCart}
            onCloseCart={handleCloseCart}
            products={products}
            cartItems={cartItems}
            donation={donation}
          />
        )}
      </Page>

      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      >
        <DialogTitle disableTypography>
          <Typography variant="h4" align="center">
            Thanks for your support!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography align="center" gutterBottom>
            A confirmation email containing your order details has been sent to
            the email address provided during checkout.
          </Typography>
          <Typography align="center">
            For questions regarding your order or contribution, please contact:{' '}
            {
              <a
                href="mailto:support@vanscend.org"
                target="_blank"
                rel="noreferrer"
              >
                support@vanscend.org
              </a>
            }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowConfirmation(false)}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Support;
