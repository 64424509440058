import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
import { Box, Button, TextField } from '@material-ui/core';

const ResetPasswordForm = ({ formik }) => {
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <TextField
          fullWidth
          variant="outlined"
          {...getFieldProps('email')}
          type="email"
          label="Email address"
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
        />
        <Box style={{ marginTop: 5 }}>
          <Button
            style={{ color: 'blue' }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Request Code
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};

ResetPasswordForm.propTypes = {
  formik: PropTypes.object.isRequired
};

export default ResetPasswordForm;
