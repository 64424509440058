import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 165px)',
    position: 'relative',
    '& video': {
      objectFit: 'contain'
    }
  }
}));

const Hero = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ReactPlayer
        url="/static/videos/hero-new.mp4"
        playing
        loop
        muted
        width="100%"
        height="100%"
      />
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
