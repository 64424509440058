import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Page, Header } from '../../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden'
  },
  legalBody: {
    padding: '20px 50px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px'
    }
  }
}));

const Disclaimer = () => {
  const classes = useStyles();

  return (
    <Page title="Website Disclaimer" className={classes.root}>
      <Header title="Disclaimer" />
      <div className={classes.legalBody}>
        <p>
          This website is owned and operated by Vanscend (&quot;<b>Company</b>
          ,&quot; &quot;
          <b>we</b>,&quot; or &quot;<b>us</b>&quot;).
        </p>
        <br />
        <br />
        <p>
          This Disclaimer, along with the Terms of Use and Privacy Policy,
          governs your access to and use of www.vanscend.org, including any
          content, functionality and services offered on or through
          www.vanscend.org (the “<b>Website</b>”), whether as a guest or a
          registered user.
        </p>
        <br />
        <p>
          Please read the Disclaimer carefully before you start to use the
          Website.{' '}
          <b>
            By using the Website or by clicking to accept or agree to the Terms
            of Use when this option is made available to you, you accept and
            agree to be bound and abide by the Disclaimer.
          </b>{' '}
          If you do not want to agree to the Disclaimer, you must not access or
          use the Website.
        </p>
        <br />
        <p>
          <b>For Educational And Informational Purposes Only</b>
        </p>
        <br />
        <p>
          The information contained on this Website and the resources available
          for download through this website are for educational and
          informational purposes only.
        </p>
        <br />
        <p>
          <b>Not Professional Advice</b>
        </p>
        <br />
        <p>
          The information contained on this Website and the resources available
          for download through this website is not intended as, and shall not be
          understood or construed as legal advice, medical advice, financial
          advice, health advice, fitness advice or any other form of
          professional advice. While the employees and/or owners of the Company
          are professionals and the information provided on this Website relates
          to issues within the Company&apos;s area of professionalism, the
          information contained on this Website is not a substitute for advice
          from a professional who is aware of the facts and circumstances of
          your individual situation.
        </p>
        <br />
        <p>
          We have done our best to ensure that the information provided on this
          Website and the resources available for download are accurate and
          provide valuable information. Regardless of anything to the contrary,
          nothing available on or through this Website should be understood as a
          recommendation that you should not consult with a professional to
          address your particular information. The Company expressly recommends
          that you seek advice from a professional.
        </p>
        <br />
        <p>
          Neither the Company nor any of its employees or owners shall be held
          liable or responsible for any errors or omissions on this website or
          for any damage you may suffer as a result of failing to seek competent
          advice from a professional who is familiar with your situation.
        </p>
        <br />
        <p>
          <b>No Professional&dash;Client Relationship</b>
        </p>
        <br />
        <p>
          Your use of this Website &ndash; including implementation of any
          suggestions set out in this Website and/or use of any resources
          available on this Website &ndash; does not create a
          professional&dash;client relationship between you and the Company or
          any of its professionals.
          <br />
          <br />
          The Company cannot accept you as a client unless and until we
          determine that there is a fit and until various requirements, such as
          fee arrangements, are resolved. Thus, you recognize and agree that we
          have not created any professional&dash;client relationship by the use
          of this Website.
        </p>
        <br />
        <p>
          <b>User&apos;s Personal Responsibility</b>
        </p>
        <br />
        <p>
          By using this Website, you accept personal responsibility for the
          results of your actions. You agree to take full responsibility for any
          harm or damage you suffer as a result of the use, or non&dash;use, of
          the information available on this Website or the resources available
          for download from this Website. You agree to use judgment and conduct
          due diligence before taking any action or implementing any plan or
          policy suggested or recommended on this Website.
        </p>
        <br />
        <p>
          <b>No Guarantees</b>
        </p>
        <br />
        <p>
          You agree that the Company has not made any guarantees about the
          results of taking any action, whether recommended on this Website or
          not. The Company provides educational and informational resources that
          are intended to help users of this website succeed in their chosen
          endeavors and otherwise. You nevertheless recognize that your ultimate
          success or failure will be the result of your own efforts, your
          particular situation, and innumerable other circumstances beyond the
          control and/or knowledge of the Company.
        </p>
        <br />
        <p>
          You also recognize that prior results do not guarantee a similar
          outcome. Thus, the results obtained by others &ndash; whether clients
          or customers of the Company or otherwise &ndash; applying the
          principles set out in this Website are no guarantee that you or any
          other person or entity will be able to obtain similar results.
        </p>
        <br />
        <p>
          <b>Errors And Omissions</b>
        </p>
        <br />
        <p>
          This World Wide Web Site is a public resource of general information
          that is intended, but not promised or guaranteed, to be correct,
          complete, and up&dash;to&dash;date. We have taken reasonable steps to
          ensure that the information contained in this Website is accurate, but
          we cannot represent that this Website is free of errors. You accept
          that the information contained on this Website may be erroneous and
          agree to conduct due diligence to verify any information obtained from
          this Website and/or resources available on it prior to taking any
          action. You expressly agree not to rely upon any information contained
          in this website.
        </p>
        <br />
        <p>
          <b>Reviews</b>
        </p>
        <br />
        <p>
          At various times, we may provide reviews of products, services, or
          other resources. This may include reviews of books, services, and/or
          software applications. Any such reviews will represent the
          good&dash;faith opinions of the author of such review. The products
          and services reviewed may be provided to the Company for free or at a
          reduced price as an incentive to provide a review.
        </p>
        <br />
        <p>
          Regardless of any such discounts, we will provide honest reviews of
          these products and/or services. You recognize that you should conduct
          your own due diligence and should not rely solely upon any reviews
          provided on this website.
        </p>
        <br />
        <p>
          We will disclose the existence of any discounts or incentives received
          in exchange for providing a review of a product. If you would like
          more information about any such discounts and incentives, send an
          email to info (a) vanscend.org that includes the title of the reviewed
          product as the subject line. We will respond via email and disclose
          any incentives or discounts we received in association with any such
          review.
        </p>
        <br />
        <p>
          <b>Affiliate Links</b>
        </p>
        <br />
        <p>
          From time to time, the Company participates in affiliate marketing and
          may allow affiliate links to be included on some of our pages. This
          means that we may earn a commission if/when you click on or make
          purchases via affiliate links.
        </p>
        <br />
        <p>
          As a policy, the Company will only affiliate with products, services,
          coaches, consultants, and other experts that we believe will provide
          value to our customers and followers.
        </p>
        <br />
        <p>
          The Company will inform you when one of the links constitutes an
          affiliate link.
        </p>
        <br />
        <p>
          You recognize that it remains your personal responsibility to
          investigate whether any affiliate offers are right for your business
          and will benefit you. You will not rely on any recommendation,
          reference, or information provided by the Company but will instead
          conduct your own investigation and will rely upon your investigation
          to decide whether to purchase the affiliate product or service.
        </p>
        <br />
        <p>
          <b>No Endorsements</b>
        </p>
        <br />
        <p>
          From time to time, the Company will refer to other products, services,
          coaches, consultants, and/or experts. Any such reference is not
          intended as an endorsement or statement that the information provided
          by the other party is accurate. The Company provides this information
          as a reference for users. It is your responsibility to conduct your
          own investigation and make your own determination about any such
          product, service, coach, consultant, and/or expert.
        </p>
        <br />
        <p>
          <b>Testimonials</b>
        </p>
        <br />
        <p>
          At various places on this Website, you may find testimonials from
          clients and customers of the products and services offered on this
          Website or by the Company. The testimonials are actual statements made
          by clients and/or customers and have been truthfully conveyed on this
          Website.
        </p>
        <br />
        <p>
          Although these testimonials are truthful statements about results
          obtained by these clients and/or customers, the results obtained by
          these clients and/or customers are not necessarily typical. You
          specifically recognize and agree that the testimonials are not a
          guarantee of results that you or anyone else will obtain by using any
          products or services offered on this Website or by the Company.
        </p>
        <br />
        <p>
          <b>Earnings Disclaimer</b>
        </p>
        <br />
        <p>
          From time to time, the Company may report on the success of one of its
          existing or prior clients/customers. The information about this
          success is accurately portrayed by the Customer. You acknowledge that
          the prior success of others does not guarantee your success.
        </p>
        <p>
          As with any business, your results may vary and will be based on your
          individual capacity, business experience, expertise, and level of
          desire. There are no guarantees concerning the level of success you
          may experience. There is no guarantee that you will make any income at
          all and you accept the risk that the earnings and income statements
          differ by individual. Each individual&apos;s success depends on his or
          her background, dedication, desire and motivation.
        </p>
        <br />
        <p>
          The use of our information, products and services should be based on
          your own due diligence and you agree that the Company is not liable
          for any success or failure of your business that is directly or
          indirectly related to the purchase and use of our information,
          products, and services reviewed or advertised on this Website.
        </p>
        <br />
        <p>
          <b>No Warranties</b>
        </p>
        <br />
        <p>
          THE COMPANY MAKES NO WARRANTIES REGARDING THE PERFORMANCE OR OPERATION
          OF THIS WEBSITE. THE COMPANY FURTHER MAKES NO REPRESENTATIONS OR
          WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE INFORMATION,
          CONTENTS, MATERIALS, DOCUMENTS, PROGRAMS, PRODUCTS, BOOKS, OR SERVICES
          INCLUDED ON OR THROUGH THIS WEBSITE. TO THE FULLEST EXTENT PERMISSIBLE
          UNDER THE LAW, THE COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR
          IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
          FOR A PARTICULAR PURPOSE.
        </p>
        <br />
        <p>
          <b>Limitation of Liability</b>
        </p>
        <br />
        <p>
          YOU AGREE TO ABSOLVE THE COMPANY OF ANY AND ALL LIABILITY OR LOSS THAT
          YOU OR ANY PERSON OR ENTITY ASSOCIATED WITH YOU MAY SUFFER OR INCUR AS
          A RESULT OF USE OF THE INFORMATION CONTAINED ON THIS WEBSITE AND/OR
          THE RESOURCES YOU MAY DOWNLOAD FROM THIS WEBSITE. YOU AGREE THAT THE
          COMPANY SHALL NOT BE LIABLE TO YOU FOR ANY TYPE OF DAMAGES, INCLUDING
          DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EQUITABLE, OR CONSEQUENTIAL
          LOSS OR DAMAGES FOR USE OF THIS WEBSITE.
        </p>
        <br />
        <p>
          THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
          AVAILABLE THROUGH THE WEBSITE MAY INCLUDE INACCURACIES OR
          TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
          INFORMATION HEREIN. THE COMPANY AND/OR ITS SUPPLIERS MAY MAKE
          IMPROVEMENTS AND/OR CHANGES IN THE WEBSITE AT ANY TIME.
        </p>
        <br />
        <p>
          THE COMPANY AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
          SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF
          THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
          CONTAINED ON THE WEBSITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT
          PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS,
          SERVICES AND RELATED GRAPHICS ARE PROVIDED &quot;AS IS&quot; WITHOUT
          WARRANTY OR CONDITION OF ANY KIND. THE COMPANY AND/OR ITS SUPPLIERS
          HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
          INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
          INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON&dash;INFRINGEMENT.
        </p>
        <br />
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          THE COMPANY AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT,
          PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
          WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE,
          DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE
          OR PERFORMANCE OF THE WEBSITE, WITH THE DELAY OR INABILITY TO USE THE
          WEBSITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
          SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
          RELATED GRAPHICS OBTAINED THROUGH THE WEBSITE, OR OTHERWISE ARISING
          OUT OF THE USE OF THE WEBSITE, WHETHER BASED ON CONTRACT, TORT,
          NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF THE COMPANY OR ANY
          OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
          BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
          LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE
          ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH
          ANY PORTION OF THE WEBSITE, OR WITH ANY OF THESE TERMS OF USE, YOUR
          SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE WEBSITE.
        </p>
        <br />
        <p>
          <b>Contact Us</b>
        </p>
        <br />
        <p>
          The Company welcomes your questions or comments regarding the
          Disclaimer.
        </p>
      </div>
    </Page>
  );
};

export default Disclaimer;
