import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Popover } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'inherit',
    boxShadow: theme.shadows[5],
    border: `solid 1px ${theme.palette.background.dark}`
  },
  arrow: {
    [theme.breakpoints.up('sm')]: {
      top: -7,
      zIndex: 1,
      width: 12,
      right: 20,
      height: 12,
      content: "''",
      position: 'absolute',
      borderRadius: '0 0 4px 0',
      transform: 'rotate(-135deg)',
      background: theme.palette.vanscend.white,
      borderRight: `solid 2px ${theme.palette.background.dark}`,
      borderBottom: `solid 2px ${theme.palette.background.dark}`
    }
  }
}));

const PopoverMenu = ({ children, width, padding, ...rest }) => {
  const classes = useStyles();

  return (
    <Popover
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      classes={{
        root: classes.root,
        paper: classes.paper
      }}
      {...rest}
    >
      <span className={classes.arrow} />

      <Box style={{ width: width, padding: padding, maxWidth: '100%' }}>
        {children}
      </Box>
    </Popover>
  );
};

PopoverMenu.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default PopoverMenu;
