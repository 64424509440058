import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';

import useStyles from '../styles';

const HandleForm = ({ formik }) => {
  const classes = useStyles();
  const { errors, touched, getFieldProps } = formik;

  return (
    <Grid container direction="row" className={classes.formContent}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Username"
          {...getFieldProps('username')}
          error={Boolean(touched.username && errors.username)}
          helperText={touched.username && errors.username}
        />
      </Grid>
    </Grid>
  );
};

HandleForm.propTypes = {
  formik: PropTypes.object.isRequired
};

export default HandleForm;
