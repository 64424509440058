import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Typography
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  loadVideos,
  updateProfileVideo,
  answerProfileVideo
} from '../../../../redux';
import { Page, VideoPlayer, VideoQuestions } from '../../../../components';

const useStyles = makeStyles((theme) => ({
  root: {},
  section: {
    minHeight: '100%',
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(3),
    borderRadius: 8
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  backButton: {
    marginBottom: theme.spacing(1)
  },
  relatedLessonsTitle: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1)
  },
  relatedLessonsBody: {
    fontSize: '1rem',
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  }
}));

const WatchVideo = () => {
  const profile = useSelector((state) => state.profile);
  const videos = useSelector((state) => state.learning);
  const [loadedVideos, setLoadedVideos] = useState(false);
  const [video, setVideo] = useState();
  const [QA, setQA] = useState([]);
  const classes = useStyles();
  const { videoId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userProgress = new URLSearchParams(location.search).get('userProgress');
  const clusterName = new URLSearchParams(location.search).get('clusterName');

  useEffect(() => {
    if (videos.length === 0) {
      dispatch(loadVideos());
      setLoadedVideos(true);
    } else {
      const urlVideo = videos.find((video) => video.id === videoId);
      if (urlVideo) {
        setVideo(urlVideo);
      }
      if (!urlVideo && !loadedVideos) {
        dispatch(loadVideos());
        setLoadedVideos(true);
      }
    }
  }, [dispatch, videos, loadedVideos, videoId]);

  useEffect(() => {
    if (video) {
      const profileVideo = profile.videos.find(
        (profileVideo) => profileVideo.video.id === video.id
      );
      setQA(
        profileVideo
          ? profileVideo.qa
          : video.questions.map((videoQuestion) => {
              return { question: videoQuestion, answer: '' };
            })
      );
    }
  }, [video, profile]);

  const onBackButtonClick = () => {
    navigate(
      '/my-vanscend/learning' +
        (clusterName ? `?clusterName=${clusterName}` : '')
    );
  };

  const updateProgress = (progress) => {
    if (progress) {
      dispatch(
        updateProfileVideo(
          profile.id,
          videoId,
          progress.seconds,
          progress.duration
        )
      );
    }
  };

  const submitQA = async (qa) => {
    const profileVideo = profile.videos.find(
      (profileVideo) => profileVideo.video.id === video.id
    );
    await dispatch(
      answerProfileVideo(profile.id, videoId, profileVideo.lengthWatched, qa)
    );
  };

  return (
    <Page
      title={video ? `Learning ${video.name}` : 'Learning'}
      className={classes.root}
    >
      <div className={classes.section}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<ArrowBackIosIcon />}
          className={classes.backButton}
          onClick={onBackButtonClick}
        >
          Back
        </Button>
        <Card>
          <CardHeader
            title={
              video ? (
                <Typography variant="h5">{video.name}</Typography>
              ) : (
                <Skeleton variant="text" width={300} />
              )
            }
          />
          <Divider className={classes.divider} />
          <VideoPlayer
            url={video ? video.link : ''}
            progress={userProgress ? userProgress : 0}
            updateProgress={updateProgress}
          />
          {video && video.description ? (
            <>
              <Divider className={classes.divider} />
              <Typography variant="h5" className={classes.relatedLessonsTitle}>
                Related Lessons
              </Typography>
              <Typography
                variant="body1"
                className={classes.relatedLessonsBody}
              >
                {video.description}
              </Typography>
            </>
          ) : (
            ''
          )}
          {QA.length ? (
            <>
              <Divider className={classes.divider} />
              <VideoQuestions questions={QA} submitQA={submitQA} />
            </>
          ) : (
            ''
          )}
        </Card>
      </div>
    </Page>
  );
};

export default WatchVideo;
