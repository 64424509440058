/* eslint-disable react/display-name */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { AuthorizedRoute, DevRoute, LearningRoute } from './components';
import HomeLayout from './layouts/HomeLayout';
import LearningLayout from './layouts/LearningLayout';
import {
  Home,
  Support,
  LoginView,
  RegisterView,
  ResetPasswordView,
  PageNotFoundView,
  ProfileSetupView,
  MyProfile,
  Settings,
  MyVanscendLanding,
  Learning,
  WatchVideo,
  Contact,
  About,
  Impact,
  Terms,
  Privacy,
  Disclaimer
} from './views';

const routes = [
  {
    path: '/',
    element: <HomeLayout />,
    children: [
      { path: '/', element: <Home /> },
      { path: 'impact', element: <Impact /> },
      { path: 'support', element: <Support /> },
      { path: 'contact', element: <Contact /> },
      { path: 'about', element: <About /> },
      { path: 'terms', element: <Terms /> },
      { path: 'privacy', element: <Privacy /> },
      { path: 'disclaimer', element: <Disclaimer /> },
      { path: '404', element: <PageNotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'my-vanscend',
    element: <LearningLayout />,
    children: [
      {
        path: '',
        element: <MyVanscendLanding />
      },
      {
        path: 'learning',
        element: (
          <LearningRoute>
            <Learning />
          </LearningRoute>
        )
      },
      {
        path: 'learning/:videoId',
        element: (
          <LearningRoute>
            <WatchVideo />
          </LearningRoute>
        )
      },
      {
        path: 'profile',
        element: (
          <LearningRoute>
            <MyProfile />
          </LearningRoute>
        )
      },
      {
        path: 'mail',
        element: (
          <DevRoute>
            <LearningRoute>
              <MyVanscendLanding />
            </LearningRoute>
          </DevRoute>
        )
      },
      {
        path: 'settings',
        element: (
          <DevRoute>
            <LearningRoute>
              <Settings />
            </LearningRoute>
          </DevRoute>
        )
      },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: <LoginView />
      },
      {
        path: 'register',
        element: <RegisterView />
      },
      {
        path: 'password-reset',
        element: <ResetPasswordView />
      },
      { path: '', element: <Navigate to="/auth/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'profile-setup',
    children: [
      {
        path: '',
        element: (
          <AuthorizedRoute>
            <ProfileSetupView />
          </AuthorizedRoute>
        )
      },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
