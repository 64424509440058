import React from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, ListItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto',
    fontSize: '1.15rem'
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  isHome: {
    [theme.breakpoints.up('lg')]: {
      color: `${theme.palette.text.light} !important`
    },
    color: `${theme.palette.text.secondary} !important`,
    '& $icon': {
      [theme.breakpoints.up('lg')]: {
        color: theme.palette.text.light
      },
      color: theme.palette.text.secondary
    }
  }
}));

const NavItem = ({ href, icon: Icon, title }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <ListItem className={classes.item} disableGutters>
      <Button
        activeClassName={classes.active}
        className={clsx(classes.button, {
          [classes.isHome]: pathname !== href
        })}
        component={RouterLink}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
