import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Container, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.vanscend.black,
    color: theme.palette.background.paper,
    padding: theme.spacing(3, 0, 3)
  },
  submitButton: {
    color: theme.palette.vanscend.black
  }
}));

const EarlyAdopter = ({ setHasSubmitted }) => {
  const classes = useStyles();

  const onSubmit = () => setHasSubmitted(false);

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Typography variant="h3" align="center" gutterBottom>
          Thanks for joining the Vanscend community!
        </Typography>
        <Typography variant="h6" align="center" paragraph>
          Our goal is to reach millions of people as fast as possible. We will
          share periodic updates with you. If you know someone else who wants to
          sign up please click the button below.
        </Typography>
        <Box textAlign="center" style={{ marginTop: 20 }}>
          <Button
            className={classes.submitButton}
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            New Entry
          </Button>
        </Box>
      </Container>
    </div>
  );
};

EarlyAdopter.propTypes = {
  setHasSubmitted: PropTypes.func.isRequired
};

export default EarlyAdopter;
