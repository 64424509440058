import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import MainNavDrawer from '../../components/MainNavDrawer';
import MainTopNav from '../../components/MainTopNav';
import MainFooter from '../../components/MainFooter';
import TopBar from './TopBar';
import { LearningNav } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.vanscend.black,
    height: '100%'
  },
  authRoot: {
    backgroundColor: theme.palette.vanscend.black,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    paddingTop: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(8)
    }
  },
  authContent: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(2)
  }
}));

const LearningLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const auth = useSelector((state) => state.auth);

  if (auth.isAuthenticated) {
    return (
      <div className={classes.authRoot}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
        <LearningNav
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.authContent}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <MainNavDrawer
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <MainTopNav onMobileNavOpen={() => setMobileNavOpen(true)} />
      <main className={classes.content}>
        <Outlet />
      </main>
      <MainFooter />
    </div>
  );
};

export default LearningLayout;
