const dev = {
  cognito: {
    REGION: process.env.REACT_APP_AWS_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
  },
  s3: {
    REGION: process.env.REACT_APP_AWS_REGION,
    BUCKET: process.env.REACT_APP_AWS_S3_BUCKET_NAME
  },
  profileApiGateway: {
    REGION: process.env.REACT_APP_AWS_REGION,
    URL: process.env.REACT_APP_PROFILE_API_URL
  },
  videoApiGateway: {
    REGION: process.env.REACT_APP_AWS_REGION,
    URL: process.env.REACT_APP_VIDEO_API_URL
  }
};

const prod = {
  cognito: {
    REGION: process.env.REACT_APP_AWS_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
  },
  s3: {
    REGION: process.env.REACT_APP_AWS_REGION,
    BUCKET: process.env.REACT_APP_AWS_S3_BUCKET_NAME
  },
  profileApiGateway: {
    REGION: process.env.REACT_APP_AWS_REGION,
    URL: process.env.REACT_APP_PROFILE_API_URL
  },
  videoApiGateway: {
    REGION: process.env.REACT_APP_AWS_REGION,
    URL: process.env.REACT_APP_VIDEO_API_URL
  }
};

const config = {
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === 'prod' ? prod : dev)
};

export default config;
