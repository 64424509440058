import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import {
  makeStyles,
  CardMedia,
  CardContent,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 250,
    height: 400,
    boxShadow: theme.shadows[10]
  },
  media: {
    height: '100%',
    maxHeight: 275,
    maxWidth: '100%'
  },
  itemActions: {
    padding: 5
  },
  mediaTitle: {
    color: theme.palette.vanscend.green
  },
  mediaPrice: {
    color: theme.palette.vanscend.white
  }
}));

const ShopItem = ({ image, itemName, rating, price }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={image} />
      <CardContent>
        <Typography variant="h6" component="p" className={classes.mediaTitle}>
          {itemName}
        </Typography>
        <Rating name="read-only" value={rating} readOnly />
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              variant="body2"
              component="p"
              className={classes.mediaPrice}
            >
              ${price}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color="primary" aria-label="add to shopping cart">
              <AddShoppingCartIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ShopItem.propTypes = {
  image: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  rating: PropTypes.number,
  price: PropTypes.number
};

export default ShopItem;
