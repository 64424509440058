import * as profileApi from '../../api/profileApi';
import {
  watchVideo,
  addVideo,
  answerVideo,
  updateProfile,
  updateAvatar
} from '../slices/profileSlice';

export const updateProfileDetails = (
  profileId,
  firstName,
  lastName,
  userName,
  rate,
  avatarLink
) => {
  return async (dispatch) => {
    try {
      await profileApi.updateProfile(
        profileId,
        firstName,
        lastName,
        userName,
        rate,
        avatarLink
      );
    } catch (error) {
      throw new Error(error);
    }
    dispatch(
      updateProfile({ firstName, lastName, userName, rate, avatar: avatarLink })
    );
  };
};

export const updateProfileAvatar = (profileId, file) => {
  let fileName;
  return async (dispatch) => {
    try {
      fileName = await profileApi.changeProfileAvatar(profileId, file);
    } catch (error) {
      throw new Error(error);
    }
    dispatch(
      updateAvatar({ fileName: process.env.REACT_APP_AVATAR_URI + fileName })
    );
  };
};

export const updateProfileVideo = (profileId, videoId, length, duration) => {
  return async (dispatch) => {
    try {
      await profileApi.watchVideo(profileId, videoId, length);
    } catch (error) {
      throw new Error(error);
    }
    dispatch(watchVideo({ videoId, length, duration }));
  };
};

export const addVideoToProfile = (profileId, video) => {
  return async (dispatch) => {
    try {
      await profileApi.addVideo(profileId, video);
    } catch (error) {
      throw new Error(error);
    }
    dispatch(addVideo({ video }));
  };
};

export const answerProfileVideo = (profileId, videoId, length, qa) => {
  return async (dispatch) => {
    try {
      await profileApi.answerVideo(profileId, videoId, length, qa);
    } catch (error) {
      throw new Error(error);
    }
    dispatch(answerVideo({ videoId, qa }));
  };
};
