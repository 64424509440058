import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  makeStyles
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    color: theme.palette.vanscend.black
  }
}));

const RegisterForm = ({ formik }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <TextField
          style={{ marginTop: 10 }}
          fullWidth
          variant="outlined"
          name="email"
          type="email"
          label="Email address"
          {...getFieldProps('email')}
          error={
            Boolean(touched.email && errors.email) ||
            (errors.afterSubmit &&
              errors.afterSubmit.code === 'UsernameExistsException')
          }
          helperText={
            (touched.email && errors.email) ||
            (errors.afterSubmit &&
              errors.afterSubmit.code === 'UsernameExistsException' &&
              errors.afterSubmit.message)
          }
        />
        <Box sx={{ mb: 3 }} />
        <TextField
          style={{ marginTop: 10 }}
          fullWidth
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          {...getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
          error={
            Boolean(touched.password && errors.password) ||
            (errors.afterSubmit &&
              errors.afterSubmit.code === 'InvalidPasswordException')
          }
          helperText={
            (touched.password && errors.password) ||
            (errors.afterSubmit &&
              errors.afterSubmit.code === 'InvalidPasswordException' &&
              errors.afterSubmit.message.split(':')[1])
          }
        />
        <Box style={{ marginTop: 10 }}>
          <Button
            className={classes.submitButton}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Register
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};

RegisterForm.propTypes = {
  formik: PropTypes.object.isRequired
};

export default RegisterForm;
