import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 0)
    },
    background: theme.palette.vanscend.black,
    position: 'relative'
  },
  footerContainer: {
    width: 280,
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10)
    }
  },
  logoContainerItem: {
    paddingTop: 0
  },
  logoContainer: {
    margin: '0 auto',
    width: 120,
    height: 40
  },
  logoImage: {
    width: '100%',
    height: '100%'
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    '&:hover': {
      background: 'transparent'
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  icon: {
    fontSize: 24
  },
  loginWrapper: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 0
  },
  loginLink: {
    color: '#69A6F8',
    textDecoration: 'underline'
  },
  copyright: {
    color: theme.palette.vanscend.white,
    textAlign: 'center'
  }
}));

const SplashFooter = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.footerContainer}>
        <List disablePadding>
          <ListItem disableGutters className={classes.logoContainerItem}>
            <div className={classes.logoContainer}>
              <a href="/" title="Vanscend">
                <img
                  className={classes.logoImage}
                  src="/static/brand/companyLogo.svg"
                />
              </a>
            </div>
          </ListItem>
        </List>
      </div>
      <Typography paragraph={false} className={classes.copyright}>
        ©{new Date().getFullYear()} Vanscend - All rights reserved
      </Typography>
      <Typography paragraph={false} className={classes.loginWrapper}>
        <RouterLink to="/auth/login" className={classes.loginLink}>
          Login
        </RouterLink>
      </Typography>
    </div>
  );
};

export default SplashFooter;
