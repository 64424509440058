import { API, Auth } from 'aws-amplify';

export const loadVideos = async () => {
  const options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }
  };
  return API.get('videos', '/videos', options);
};
