import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
        maxWidth: '100%'
      },
      body: {
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        '&::-webkit-scrollbar': {
          width: 0,
          height: 0
        },
        fontFamily: 'Montserrat, Roboto, Arial, sans-serif',
        backgroundColor: theme.palette.vanscend.white
      },
      a: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
      },
      img: {
        display: 'block',
        maxWidth: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.default
      },
      '.MuiSelect-icon': {
        color: theme.palette.vanscend.green
      }
    }
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
