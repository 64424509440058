import React from 'react';
import IFrame from 'react-iframe';
import { Link as RouterLink } from 'react-router-dom';
import { colors, Grid, makeStyles, Typography } from '@material-ui/core';
import { Page, Header } from '../../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  topImgSection: {
    marginTop: -150,
    background: 'url(/static/backgrounds/vanscending.jpg) no-repeat center',
    backgroundSize: 'cover',
    width: '100%',
    height: 'calc(100vh - 165px)'
  },
  section: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(6, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 2)
    }
  },
  orgTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.7rem'
    }
  },
  orgText: {
    paddingTop: '2.5em',
    paddingBottom: '4vh',
    fontSize: '1.2rem',
    color: colors.grey[700]
  },
  orgSig: {
    textAlign: 'right',
    paddingRight: '10vw',
    marginTop: '2.5vh',
    fontWeight: 'bold',
    display: 'block'
  },
  break: {
    borderBottom: '1px solid',
    borderColor: colors.grey[100],
    width: '75%',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '85%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  padding: {
    paddingTop: '6vh'
  },
  lgSectionTitle: {
    paddingBottom: '1em',
    fontSize: '1.85rem'
  },
  lgSectionText: {
    color: colors.grey[700],
    fontSize: '1.2rem',
    '& div': {
      padding: theme.spacing(1, 0)
    }
  },
  teamTitle: {
    fontSize: '1.85rem',
    paddingBottom: '1em',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  listItem: {
    '&:first-child': {
      paddingTop: 0,
      marginTop: -18
    },
    paddingTop: 25
  },
  listTitle: {
    paddingLeft: 10
  },
  listDescription: {
    marginLeft: 87,
    fontSize: '1.1rem'
  },
  video: {
    width: '95vw',
    height: '30vh',
    [theme.breakpoints.up('md')]: {
      width: '65vw',
      height: '50vh'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-15vh',
      height: '50vh'
    }
  },
  videoTitle: {
    textAlign: 'center',
    fontSize: 'min(max(3vw, 80px), 36px)',
    fontWeight: 'bold',
    paddingTop: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '5vw'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-12vh'
    }
  },
  siteLink: {
    textDecoration: 'underline',
    color: theme.palette.vanscend.blue
  }
}));

const About = () => {
  const classes = useStyles();

  return (
    <Page title="About" className={classes.root}>
      <Header title="About" />
      <div className={classes.topImgSection} />
      <section className={classes.section}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={10}
        >
          <Grid item sm={12} md={8}>
            <Typography variant="h3" className={classes.orgTitle}>
              Our Organization
            </Typography>
            <Typography className={classes.orgText}>
              Vanscend is the 501(c)(3) nonprofit social venture I founded in
              early 2020. Over 20 years of efforts focused on the intersection
              of addiction recovery and entrepreneurship inspired me to move
              forward with building this program.
              <br />
              <br />
              Vanscend&apos;s original mission was to advance the
              entrepreneurial interests of people in recovery from addiction. We
              quickly realized that Vanscend&apos;s curriculum could be helpful
              to other large populations of people who might be seen as
              underdogs, second-chance, start-over and more. Vanscend is
              committed to making entrepreneurship more accessible and relevant
              to the people who need it most, for free.
              <br />
              <br />
              Watch the videos below to see <b>Vanscend in action!</b>
              <br />
              <br />
              Visit our{' '}
              <RouterLink to="/impact" className={classes.siteLink}>
                <b>Impact page</b>
              </RouterLink>{' '}
              to see how Vanscend is making a real difference for thousands of
              people at a very low cost. Visit the{' '}
              <RouterLink to="/my-vanscend" className={classes.siteLink}>
                <b>MyVanscend page</b>
              </RouterLink>{' '}
              to access Vanscend’s online learning platform with 100 free video
              lessons totaling 16+ hours! Visit our{' '}
              <RouterLink to="/support" className={classes.siteLink}>
                <b>Support page</b>
              </RouterLink>{' '}
              if you would like to make a donation.
              <span className={classes.orgSig}>~ Dave Garvey, Founder</span>
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <IFrame
              url="https://player.vimeo.com/video/779026663?h=0fd3898e8c"
              frameBorder="0"
              allow="fullscreen; picture-in-picture"
              allowFullScreen
              className={classes.video}
              title="Vanscend For All"
            />
            <Typography variant="h3" className={classes.videoTitle}>
              Vanscend For All Audiences
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <IFrame
              url="https://player.vimeo.com/video/715704724?h=6257f35743"
              frameBorder="0"
              allow="fullscreen; picture-in-picture"
              allowFullScreen
              className={classes.video}
              title="Vanscend For Treatment Centers"
            />
            <Typography variant="h3" className={classes.videoTitle}>
              Vanscend For Treatment Centers
            </Typography>
          </Grid>
          <hr className={classes.break} />
          <Grid item container>
            <Typography variant="h3" className={classes.teamTitle}>
              Vanscend would never be a reality without the active involvement
              from this team
            </Typography>
            <Grid item md={9} sm={10} xs={12}>
              <img
                className={classes.teamImage}
                src="/static/images/aboutBoard.jpg"
                alt="The vanscend team"
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={8}
          >
            <Grid item sm={12}>
              <Typography variant="h4" className={classes.lgSectionTitle}>
                Read more about it here:
              </Typography>
              <div className={classes.lgSectionText}>
                <div>
                  <a
                    href="https://www.linkedin.com/posts/dtgarvey_entrepreneurship-nonprofit-addictionrecovery-activity-7008816568662925312-ukgW/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'underline', color: '#005AC2' }}
                  >
                    Vanscend Launch Announcement
                  </a>{' '}
                  on LinkedIn
                </div>
                <div>
                  <a
                    href="https://www.linkedin.com/pulse/vanscend-secures-grant-hiring-dave-garvey/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'underline', color: '#005AC2' }}
                  >
                    Vanscend Grant Announcement
                  </a>{' '}
                  on LinkedIn
                </div>
                <div>
                  <a
                    href="https://www.linkedin.com/company/68972011/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'underline', color: '#005AC2' }}
                  >
                    Vanscend
                  </a>{' '}
                  on LinkedIn
                </div>
                <div>
                  <a
                    href="https://www.linkedin.com/in/dtgarvey/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'underline', color: '#005AC2' }}
                  >
                    Dave Garvey
                  </a>{' '}
                  on LinkedIn
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </section>
    </Page>
  );
};

export default About;
