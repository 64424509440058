import React, { useState, useRef } from 'react';
import { Link as RouterLink, NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  colors,
  AppBar,
  Avatar,
  Box,
  Button,
  Hidden,
  IconButton,
  Link,
  List,
  Toolbar,
  makeStyles,
  ListItem
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { Logo, LogoGlitch, PopoverMenu } from '.';
import { useOffSetTop } from '../hooks';
import { logout } from '../redux';

const menuLinks = [
  { title: 'Home', href: '/' },
  { title: 'MyVanscend', href: '/my-vanscend' },
  { title: 'Support', href: '/support' },
  { title: 'About', href: '/about' },
  { title: 'Impact', href: '/impact' },
  { title: 'Contact', href: '/contact' }
];
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  toolbar: {
    backgroundColor: theme.palette.vanscend.slate,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '30px'
    }
  },
  toolbarShadow: {
    left: 0,
    right: 0,
    bottom: 0,
    height: 64,
    zIndex: -1,
    content: "''",
    position: 'absolute',
    boxShadow: theme.shadows[3]
  },
  desktopMenu: {
    '& > * ': {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(5)
    },
    '& > *:last-child ': {
      marginRight: theme.spacing(3)
    }
  },
  isDesktopActive: {
    color: `${theme.palette.primary.main} !important`
  },
  isHome: { color: `${colors.grey[200]} !important` },
  buttonContainter: {
    display: 'flex',
    alignItems: 'center',
    '& > * ': {
      textTransform: 'none',
      marginRight: theme.spacing(1)
    }
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'space-between',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  mobileMenuButton: {
    color: theme.palette.vanscend.white
  }
}));

const MainTopNav = ({ onMobileNavOpen }) => {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.profile);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const offset = useOffSetTop(64);
  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef(null);

  const handleLogout = async () => {
    try {
      await dispatch(logout());
    } catch (err) {
      alert(err.message);
    }
  };
  const renderMenuDesktop = (
    <div className={classes.desktopMenu}>
      {menuLinks.map((link) => (
        <Link
          exact="true"
          to={
            link.href === '/my-vanscend' && auth.isAuthenticated
              ? `${link.href}/learning`
              : link.href
          }
          key={link.title}
          color="initial"
          underline="none"
          variant="subtitle2"
          component={NavLink}
          activeClassName={classes.isDesktopActive}
          className={clsx({
            [classes.isHome]: pathname !== link.href
          })}
        >
          {link.title}
        </Link>
      ))}
    </div>
  );

  return (
    <AppBar color="transparent" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Hidden mdDown>
            <LogoGlitch />
          </Hidden>
          <Hidden lgUp>
            <Logo />
          </Hidden>
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          {renderMenuDesktop}
          {!auth.isAuthenticated ? (
            <Box className={classes.buttonContainter}>
              <Button
                underline="none"
                variant="outlined"
                color="primary"
                component={RouterLink}
                to={'/auth/login'}
              >
                Log In
              </Button>
              <Button
                style={{ color: 'black' }}
                variant="contained"
                color="primary"
                component={RouterLink}
                to={'/auth/register'}
              >
                Sign Up
              </Button>
            </Box>
          ) : (
            <>
              <IconButton ref={anchorRef} onClick={() => setOpenMenu(true)}>
                <Avatar className={classes.avatar} src={profile.avatar} />
              </IconButton>
              <PopoverMenu
                width={150}
                open={openMenu}
                anchorEl={anchorRef.current}
                onClose={() => setOpenMenu(false)}
              >
                <List>
                  <ListItem className={classes.item} disableGutters>
                    <Button
                      className={classes.button}
                      component={RouterLink}
                      to="/my-vanscend/profile"
                    >
                      <span className={classes.title}>View Profile</span>
                      <DashboardIcon />
                    </Button>
                  </ListItem>
                  <ListItem className={classes.item} disableGutters>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        setOpenMenu(false);
                        handleLogout();
                      }}
                    >
                      <span className={classes.title}>Log Out</span>
                      <ExitToAppIcon />
                    </Button>
                  </ListItem>
                </List>
              </PopoverMenu>
            </>
          )}
        </Hidden>
        <Hidden lgUp>
          <IconButton
            className={classes.mobileMenuButton}
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      {offset && <span className={classes.toolbarShadow} />}
    </AppBar>
  );
};

MainTopNav.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default MainTopNav;
