import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const DevRoute = ({ children }) => {
  return process.env.NODE_ENV === 'development' ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
};

DevRoute.propTypes = {
  children: PropTypes.object.isRequired
};

export default DevRoute;
