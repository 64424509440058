import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const LearningRoute = ({ children }) => {
  const auth = useSelector((state) => state.auth);

  return auth.isAuthenticated ? children : <Navigate to="/my-vanscend" replace />;
};

LearningRoute.propTypes = {
  children: PropTypes.object.isRequired
};

export default LearningRoute;
