import profileFormModel from './profileFormModel';

const {
  formField: { firstName, lastName, username, interests }
} = profileFormModel;

export default {
  [firstName.name]: '',
  [lastName.name]: '',
  [username.name]: '',
  [interests.name]: []
};
