import React from 'react';

const LogoInverse = (props) => {
  return (
    <img
      width="200px"
      height="50px"
      alt="Vanscend Logo"
      src="/static/brand/companyLogoInverse.svg"
      {...props}
    />
  );
};

export default LogoInverse;
