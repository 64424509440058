import React from 'react';
import clsx from 'clsx';
import { makeStyles, Grid, Typography, colors } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  sectionHeader: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4)
    }
  },
  sectionTitle: {
    fontWeight: 'bold'
  },
  impactMap: {
    margin: '0 auto'
  },
  impactGrid: {
    margin: '7vh auto 0 auto',
    [theme.breakpoints.down('xs')]: {
      margin: '5vh auto 5vh auto'
    }
  },
  impactGridItem: {
    textAlign: 'center',
    paddingLeft: '5vw',
    paddingRight: '5vw',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.60em',
      paddingLeft: '4vw',
      paddingRight: '4vw'
    },
    '&:first-of-type': {
      paddingLeft: 0
    },
    '&:last-of-type': {
      paddingRight: 0
    }
  },
  impactGridItemFig: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    display: 'block'
  },
  borderRight: {
    borderRight: '3px solid',
    borderColor: colors.grey[200]
  },
  descriptiveText: {
    color: theme.palette.vanscend.blue,
    fontWeight: 'bold',
    fontStyle: 'italic',
    lineHeight: '2em'
  }
}));

const Impact = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.sectionHeader}>
        <Typography
          variant="h3"
          align="center"
          className={classes.sectionTitle}
        >
          <span>
            Vanscend is
            <Typography component="span" variant="inherit" color="primary">
              &nbsp;Impactful
            </Typography>
          </span>
        </Typography>
      </div>
      <div>
        <img
          className={classes.impactMap}
          src="/static/backgrounds/mapBg.svg"
        />
        <Grid container className={classes.impactGrid}>
          <Grid item container justifyContent="center" alignItems="center">
            <Grid
              item
              className={clsx(classes.borderRight, classes.impactGridItem)}
            >
              <span className={classes.impactGridItemFig}>$345K</span>
              <span className={classes.descriptiveText}>Capital</span>
              <br /> raised to date
            </Grid>
            <Grid
              item
              className={clsx(classes.borderRight, classes.impactGridItem)}
            >
              <span className={classes.impactGridItemFig}>2000+</span>
              <span className={classes.descriptiveText}>Individuals</span>
              <br />
              served by Vanscend
            </Grid>
            <Grid item className={classes.impactGridItem}>
              <span className={classes.impactGridItemFig}>20+</span>{' '}
              <span className={classes.descriptiveText}>Years</span>
              <br /> in the making
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Impact;
