import { createSlice } from '@reduxjs/toolkit';

export const productSlice = createSlice({
  name: 'products',
  initialState: [],
  reducers: {
    getProducts: (_state, action) => {
      const { products } = action.payload;
      return products;
    }
  }
});

const { actions, reducer } = productSlice;

export const { getProducts } = actions;

export default reducer;
