import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  withStyles,
  Grid,
  Typography,
  colors,
  Button
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Page, Header } from '../../../components';

const DonateButton = withStyles((theme) => ({
  root: {
    color: theme.palette.vanscend.green,
    backgroundColor: theme.palette.vanscend.black,
    borderColor: theme.palette.vanscend.green,
    border: '2px outset',
    '&:hover': {
      backgroundColor: 'black'
    }
  }
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden'
  },
  sectionWithSolidBg: {
    backgroundColor: theme.palette.vanscend.black,
    position: 'absolute',
    marginTop: '-8em',
    width: '100vw',
    height: '20em',
    clipPath: 'polygon(0 30%, 100% 0, 100% 70%, 0 100%)',
    zIndex: -1,
    [theme.breakpoints.down('xl')]: {
      marginTop: '-13em',
      height: '25em'
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '-13em',
      height: '27em'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-12em',
      height: '20em'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-8em',
      height: '13em'
    }
  },
  impactMap: {
    position: 'relative',
    margin: '0 auto',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      marginTop: '5em',
      padding: '0 40px 20px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '2em',
      padding: '0 10px'
    }
  },
  impactGrid: {
    position: 'relative',
    marginTop: 100,
    zIndex: 1
  },
  impactGridItem: {
    color: theme.palette.vanscend.white,
    textAlign: 'center',
    paddingLeft: '3vw',
    paddingRight: '3vw',
    fontSize: '1.8em',
    marginTop: '-8vh',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7em'
    }
  },
  impactGridItemFig: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    display: 'block'
  },
  borderRight: {
    borderRight: '3px solid',
    borderColor: colors.grey[200]
  },
  descriptiveText: {
    color: theme.palette.vanscend.blue,
    fontWeight: 'bold',
    fontStyle: 'italic',
    lineHeight: '2em'
  },
  topicsGrid: {
    paddingTop: 150,
    paddingBottom: 50,
    position: 'relative',
    zIndex: 5
  },
  topicsImage: {
    maxWidth: 400
  },
  topicDescription: {
    width: 300,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      textAlign: 'center',
      padding: '20px'
    }
  },
  topicDescriptionCallout: {
    fontWeight: 'bold',
    color: theme.palette.vanscend.green,
    fontSize: '1.5rem',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      color: 'white'
    }
  },
  topicDescriptionMain: {
    fontSize: '1.4rem',
    textAlign: 'center'
  },
  intro: {
    marginLeft: '21vw',
    marginRight: '21vw',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '5vw',
      marginRight: '5vw'
    },
    '& p': {
      marginBottom: '3vh'
    }
  },
  yearlyTopic: {
    [theme.breakpoints.down('xs')]: {
      width: '400px',
      backgroundColor: theme.palette.vanscend.green,
      margin: '-24px 0'
    }
  },
  topicsImage2: {
    [theme.breakpoints.down('xs')]: {
      margin: '-50px 0'
    }
  },
  smallerFont: {
    fontSize: '0.85em'
  },
  largerFont: {
    fontSize: '1.2em',
    fontWeight: 600
  },
  fiftyFiftySection: {
    margin: '0 3em 2em',
    listStylePosition: 'inside',
    fontWeight: 500
  },
  fiftyFiftyHeading: {
    width: '100%',
    textAlign: 'center',
    margin: '1em 0 0.5em'
  },
  fiftyFiftyBanner: {
    backgroundColor: theme.palette.vanscend.black,
    color: theme.palette.vanscend.white,
    fontSize: 'x-large',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '0 0.5em 1em'
  },
  fiftyFiftyBannerHeading: {
    color: theme.palette.vanscend.green,
    display: 'block'
  },
  buttonContainer: {
    marginBottom: '2em'
  }
}));

const Impact = () => {
  const classes = useStyles();

  return (
    <Page title="Impact">
      <div className={classes.root}>
        <Header title="Impact" />
        <img
          className={classes.impactMap}
          src="/static/backgrounds/mapBg.svg"
        />
        <Grid container className={classes.impactGrid}>
          <div className={classes.sectionWithSolidBg} />
          <Grid item container justifyContent="center" alignItems="center">
            <Grid
              item
              className={clsx(classes.borderRight, classes.impactGridItem)}
            >
              <span className={classes.impactGridItemFig}>$345K</span>
              <span className={classes.descriptiveText}>Funding</span>
              <br /> raised to date
            </Grid>
            <Grid
              item
              className={clsx(classes.borderRight, classes.impactGridItem)}
            >
              <span className={classes.impactGridItemFig}>2000+</span>
              <span className={classes.descriptiveText}>Individuals</span>
              <br />
              served by Vanscend
            </Grid>
            <Grid item className={classes.impactGridItem}>
              <span className={classes.impactGridItemFig}>20+</span>{' '}
              <span className={classes.descriptiveText}>Years</span>
              <br /> in the making
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          className={classes.topicsGrid}
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item>
              <img
                className={classes.topicsImage}
                src="/static/images/impact/2020/rocketman.png"
                alt="Man with rocket boosters flying over city skyline"
              />
            </Grid>
            <Grid item className={classes.yearlyTopic}>
              <Typography component="div" className={classes.topicDescription}>
                <div className={classes.topicDescriptionCallout}>
                  2020 was all about
                </div>
                <div className={classes.topicDescriptionMain}>
                  Launching in a pandemic
                </div>
              </Typography>
            </Grid>
            <Grid item>
              <img
                className={classes.topicsImage}
                src="/static/images/impact/2020/rollercoaster.png"
                alt="People riding a rollercoaster"
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item>
              <img
                className={classes.topicsImage}
                src="/static/images/impact/2021/team.png"
                alt="Group in a circle with hands in the middle as if preparing to say 'Go Team'"
              />
            </Grid>
            <Grid item className={classes.yearlyTopic}>
              <Typography component="div" className={classes.topicDescription}>
                <div className={classes.topicDescriptionCallout}>
                  2021 was all about
                </div>
                <div className={classes.topicDescriptionMain}>
                  Building out the team and platform
                </div>
              </Typography>
            </Grid>
            <Grid item>
              <img
                className={classes.topicsImage}
                src="/static/images/impact/2021/platform.png"
                alt="Media wall with stacked monitors wrapping around the room"
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item>
              <img
                className={classes.topicsImage}
                src="/static/images/impact/officeWork.jpg"
                alt="People working together in an office"
              />
            </Grid>
            <Grid item className={classes.yearlyTopic}>
              <Typography component="div" className={classes.topicDescription}>
                <div className={classes.topicDescriptionCallout}>
                  2022 was all about
                </div>
                <div className={classes.topicDescriptionMain}>
                  Perfecting the curriculum
                  <br />
                  Launching the online LMS
                  <br />
                  <span className={classes.smallerFont}>
                    (Learning Management System)
                  </span>
                  <br />* launched December 2022!
                </div>
              </Typography>
            </Grid>
            <Grid item>
              <img
                className={classes.topicsImage}
                src="/static/images/impact/workingMan.jpg"
                alt="Man working alone at his laptop"
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item>
              <img
                className={classes.topicsImage}
                src="/static/images/impact/2022/teaching.png"
                alt="Teacher in front of seated students in a classroom"
              />
            </Grid>
            <Grid item className={classes.yearlyTopic}>
              <Typography component="div" className={classes.topicDescription}>
                <div className={classes.topicDescriptionCallout}>
                  2023 was all about
                </div>
                <div className={classes.topicDescriptionMain}>
                  Perfecting delivery of the in-person and online programming
                  <br />* 2,100+ served by EOY!
                </div>
              </Typography>
            </Grid>
            <Grid item>
              <img
                className={classes.topicsImage}
                src="/static/images/impact/2022/group.png"
                alt="Group of adults seated in a circle having a conversation"
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item>
              <img
                className={classes.topicsImage}
                src="/static/images/impact/vanscendingA.png"
                alt="Looking out the windshield of a large camper van on a country road"
              />
            </Grid>
            <Grid item className={classes.yearlyTopic}>
              <Typography component="div" className={classes.topicDescription}>
                <div className={classes.topicDescriptionCallout}>
                  2024 and beyond!
                </div>
                <div className={classes.topicDescriptionMain}>
                  The &quot;50 States :: $200K&quot; Campaign
                  <br />
                  Serve 5,000+ people in 50 states
                </div>
              </Typography>
            </Grid>
            <Grid item>
              <img
                className={classes.topicsImage}
                src="/static/images/impact/mapFigurines.jpg"
                alt="Toy figurines on a map of the United States"
              />
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.fiftyFiftySection}>
          <h1 className={classes.fiftyFiftyHeading}>
            Vanscend&apos;s &quot;50 States :: $200K&quot; Campaign
          </h1>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item className={classes.fiftyFiftyBanner}>
              <span className={classes.fiftyFiftyBannerHeading}>5,000</span>
              People Served
            </Grid>
            <Grid item className={classes.fiftyFiftyBanner}>
              <span className={classes.fiftyFiftyBannerHeading}>50</span>
              States
            </Grid>
            <Grid item className={classes.fiftyFiftyBanner}>
              <span className={classes.fiftyFiftyBannerHeading}>
                $100K = $200K
              </span>
              Matching Gift Campaign
            </Grid>
          </Grid>
          <br />
          <p>
            <b>A message from our Founder, Dave Garvey:</b>
          </p>
          <br />
          <p>
            Since 2020 Vanscend has been making entrepreneurship more accessible
            to the people who need it most, for free.
            <br />
            <br />
            We are raising $200K so Vanscend can serve 5,000+ more people in 50
            states. Each of your dollars becomes $2 dollars for Vanscend because
            of a generous donor who has, for the next few months, committed to
            matching donations for this “50 States $200K” campaign.
            <br />
            <br />
            Starting July 1st, 2024, we will begin serving 5,000+ people across
            the U.S. with the Vanscend program. This will require approximately
            200 workshops in as many facilities. Naturally we will serve more
            than 5,000 if we are able to. The more the better!
            <br />
            <br />
            To date over 2,200 people have been served by Vanscend programming
            in-person or online. With this campaign we can triple that!
            <br />
            <br />
            We are asking you to please help us raise $100,000 – which will be
            matched with another $100,000 – for a total of $200,000. YES!!!
            Every dollar you donate will be matched 100% by our largest and most
            supportive donor who has been with us since Day 1. And YES – if your
            employer matches your donations – we could actually raise $4 for
            every $1 you donate!!
            <br />
            <br />
            We are supremely grateful for your donations. Through Vanscend you
            have the power to help entrepreneurship make monumental change in
            people’s lives. A lot of lives! Thank you for making this a reality.
            <br />
            <br />
            Until these funds are raised I will be funding this campaign
            personally but we unequivocally need your support. I cannot carry
            this program more than a couple months. On 6/18/24 we received
            confirmation from our anchor donor about their willingness to match
            so I am quickly pivoting to fundraising before the generous match
            offer expires this October. And given the overall environment – the
            sooner we raise the better!
            <br />
            <br />
            For large donors we are willing to create sponsorship or partnership
            type arrangements. We are openminded so let’s discuss!!
            <br />
            <br />
            Also we welcome your referrals to institutions, organizations and
            facilities throughout the U.S. where we can bring the Vanscend
            program. We are moving swiftly with scheduling the first 2-3 months
            of facility workshops and welcome any referrals or introductions.
            <br />
            <br />
            Contact me with any introductions or questions: dave @ vanscend.org
            <br />
            <br />
            <br />
          </p>
          <span className={classes.largerFont}>
            More on Vanscend through this year:
          </span>
          <br />
          <br />
          <ul>
            <li>
              The Vanscend program was born in 2020 to teach entrepreneurship to
              people who need it most: people in recovery from addiction and
              others who are starting over in life.
            </li>
            <br />
            <li>
              As Vanscend’s “Founder Volunteer”, I donate significant time and
              money to this organization - often working more than 40 hours a
              week with no compensation. I do this out of gratitude (because
              “gratitude” is an “action word”) and to optimize resources.
              However, all other employees and contractors are compensated. Plus
              Vanscend is a tech-enabled nonprofit with meaningful software,
              content and required services. Thankfully all our generous donors
              make this a reality. To date Vanscend has raised $350K in tax
              deductible donations.
            </li>
            <br />
            <li>
              On average Vanscend has served individuals at a cost of
              $159/person. During 2023 we found our rhythm and can serve the
              next 5,000+ for one quarter of that = $40/person. We are extremely
              proud of this accomplishment.
            </li>
            <br />
            <li>
              This 50 States campaign was the original vision for Vanscend in
              late 2019, right before the pandemic hit. Vanscend was
              incorporated in early 2020 – so we pivoted and evolved by building
              an online learning management system and the Vanscend curriculum
              which totals 16+ hours of free educational content covering
              entrepreneurship, business and life skills. People are changing
              their lives with Vanscend – and sometimes saving them.
            </li>
          </ul>
        </div>
        <Grid
          container
          justifyContent="center"
          className={classes.buttonContainer}
        >
          <RouterLink to="/support">
            <Grid item>
              <DonateButton fullWidth size="large" variant="contained">
                Donate here!
                <br />
                Every dollar is matched!
              </DonateButton>
            </Grid>
          </RouterLink>
        </Grid>
      </div>
    </Page>
  );
};

export default Impact;
