import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ChatIcon from '@material-ui/icons/Chat';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { NavItem } from '.';
import { logout } from '../redux';

const items =
  process.env.NODE_ENV === 'development'
    ? [
        {
          href: '/my-vanscend/learning',
          icon: BarChartIcon,
          title: 'MyVanscend'
        },
        {
          href: '/my-vanscend/profile',
          icon: PersonIcon,
          title: 'My Profile'
        },
        {
          href: '/my-vanscend/mail',
          icon: ChatIcon,
          title: 'Messages'
        },
        {
          href: '/my-vanscend/settings',
          icon: SettingsIcon,
          title: 'Settings'
        },
        {
          href: '/',
          icon: HomeIcon,
          title: 'Vanscend.org'
        }
      ]
    : [
        {
          href: '/my-vanscend/learning',
          icon: BarChartIcon,
          title: 'MyVanscend'
        },
        {
          href: '/my-vanscend/profile',
          icon: PersonIcon,
          title: 'My Profile'
        },
        {
          href: '/',
          icon: HomeIcon,
          title: 'Vanscend.org'
        }
      ];

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    backgroundColor: theme.palette.vanscend.black,
    [theme.breakpoints.up('lg')]: {
      borderRight: `1px solid ${theme.palette.text.secondary}`
    }
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8)
  },
  name: {
    [theme.breakpoints.up('lg')]: {
      color: theme.palette.text.light
    },
    paddingTop: theme.spacing(1)
  },
  value: {
    [theme.breakpoints.up('lg')]: {
      color: theme.palette.text.light
    }
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  title: {
    marginRight: 'auto'
  }
}));

const LearningNav = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    setValue(profile.value);
  }, [profile]);

  const handleLogout = async () => {
    try {
      onMobileClose();
      await dispatch(logout());
      navigate('/');
    } catch (err) {
      alert(err.message);
    }
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar className={classes.avatar} src={profile.avatar} />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {profile.userName ??
            `${
              profile.firstName ? profile.firstName.charAt(0).toUpperCase() : ''
            } ${
              profile.lastName ? profile.lastName.charAt(0).toUpperCase() : ''
            }`}
        </Typography>
        <Typography className={classes.value} color="textPrimary" variant="h6">
          {`Current Value: $${Math.round(parseFloat(value)).toLocaleString()}`}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List className={classes.navItems}>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Hidden lgUp>
        <Divider />
        <Box paddingLeft={2} paddingRight={2}>
          <List>
            <ListItem className={classes.item} disableGutters>
              <Button className={classes.button} onClick={handleLogout}>
                <span className={classes.title}>Log Out</span>
                <ExitToAppIcon />
              </Button>
            </ListItem>
          </List>
        </Box>
      </Hidden>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

LearningNav.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default LearningNav;
