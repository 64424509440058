import React from 'react';
import { colors, makeStyles, Grid, Typography } from '@material-ui/core';
import 'react-multi-carousel/lib/styles.css';

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3)
    }
  },
  sectionTitle: {
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5)
    }
  },
  subTitle: {
    color: colors.grey[700],
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));

const ShopSectionHeader = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignContent="center"
      className={classes.sectionHeader}
    >
      <Grid item xs={12}>
        <Typography
          variant="h3"
          align="center"
          className={classes.sectionTitle}
          color="textPrimary"
        >
          Support
          <Typography variant="inherit" color="secondary">
            {' '}
            Vanscend
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant={'h6'}
          align={'center'}
          className={classes.subTitle}
        >
          <span>Support the project by rocking some cool gear.</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ShopSectionHeader;
