import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  cardActions: {
    padding: theme.spacing(2)
  }
}));

const Notifications = ({ formik }) => {
  const classes = useStyles();
  const { handleChange, isSubmitting, handleSubmit, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Card>
          <CardHeader
            subheader="Manage the notifications"
            title="Notifications"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={6} wrap="wrap">
              <Grid item container direction="column" md={4} sm={6} xs={12}>
                <Typography gutterBottom variant="h6">
                  Notifications
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="notifications.suggestedVideos"
                      checked={values.notifications.suggestedVideos}
                      onChange={handleChange}
                    />
                  }
                  label="Suggested Videos"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="notifications.newLearningPaths"
                      checked={values.notifications.newLearningPaths}
                      onChange={handleChange}
                    />
                  }
                  label="New Learning Paths"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="notifications.newsLetter"
                      checked={values.notifications.newsLetter}
                      onChange={handleChange}
                    />
                  }
                  label="News Letter"
                />
              </Grid>
              <Grid item container direction="column" md={4} sm={6} xs={12}>
                <Typography gutterBottom variant="h6">
                  Messages
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="messages.email"
                      checked={values.messages.email}
                      onChange={handleChange}
                    />
                  }
                  label="Email"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="messages.textMessage"
                      checked={values.messages.textMessage}
                      onChange={handleChange}
                    />
                  }
                  label="Text Messages"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Grid
            item
            container
            justifyContent="flex-end"
            className={classes.cardActions}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
          </Grid>
        </Card>
      </Form>
    </FormikProvider>
  );
};

Notifications.propTypes = {
  formik: PropTypes.object.isRequired
};

export default Notifications;
