import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 8,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightBold
  },
  default: {
    color: theme.palette.vanscend.white,
    backgroundColor: theme.palette.vanscend.blue
  },
  lowStock: {
    color: theme.palette.vanscend.black,
    backgroundColor: theme.palette.vanscend.green
  },
  backordered: {
    color: theme.palette.vanscend.black,
    backgroundColor: theme.palette.vanscend.orange
  }
}));

const ProductQtyLabel = ({ variant = 'default', children }) => {
  const classes = useStyles();

  return (
    <span
      style={{
        zIndex: 9,
        top: 16,
        right: 16,
        position: 'absolute'
      }}
      className={
        variant === 'lowStock'
          ? clsx(classes.root, classes.lowStock)
          : variant === 'backordered'
          ? clsx(classes.root, classes.backordered)
          : clsx(classes.root, classes.default)
      }
    >
      {children}
    </span>
  );
};

ProductQtyLabel.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.string
};

export default ProductQtyLabel;
