import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form, FormikProvider } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {
  makeStyles,
  Box,
  Button,
  Link,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.main
  },
  submitButton: {
    color: theme.palette.vanscend.black
  }
}));

const LoginForm = ({ formik }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <TextField
          style={{ marginTop: 10 }}
          fullWidth
          variant="outlined"
          type="email"
          label="Email address"
          name="email"
          autoComplete="email"
          autoFocus
          {...getFieldProps('email')}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
        />

        <TextField
          style={{ marginTop: 10 }}
          fullWidth
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          name="password"
          autoComplete="current-password"
          {...getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
        />
        <FormHelperText error={Boolean(errors.afterSubmit)}>
          {errors.afterSubmit && errors.afterSubmit.message}
        </FormHelperText>

        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps('remember')}
                checked={values.remember}
              />
            }
            label="Remember me"
          />

          <Link
            variant="subtitle2"
            to="/auth/password-reset"
            component={RouterLink}
            className={classes.link}
          >
            Forgot password?
          </Link>
        </Box>

        <Button
          className={classes.submitButton}
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          Login
        </Button>
      </Form>
    </FormikProvider>
  );
};

LoginForm.propTypes = {
  formik: PropTypes.object.isRequired
};

export default LoginForm;
