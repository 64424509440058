import React from 'react';
import { makeStyles, useTheme, useMediaQuery, Grid } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import SkeletonProductCard from './SkeletonProductCard';
import 'react-multi-carousel/lib/styles.css';

const useStyles = makeStyles((theme) => ({
  carousel: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(3)
    }
  },
  carouselItem: {
    padding: 10
  }
}));

const SkeletonShopProducts = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('lg'), {
    defaultMatches: true
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1024, min: 480 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 30
    }
  };

  return (
    <Grid container spacing={3} className={classes.products}>
      <Grid item xs={12}>
        <Carousel
          swipeable={isMd}
          draggable={false}
          showDots={isMd}
          renderDotsOutside={true}
          responsive={responsive}
          infinite={true}
          partialVisible={true}
          autoPlay={false}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass={classes.carousel}
          removeArrowOnDeviceType={['tablet', 'mobile']}
          itemClass={classes.carouselItem}
        >
          {[1, 2, 3, 4].map((skeleton) => (
            <SkeletonProductCard key={skeleton} />
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default SkeletonShopProducts;
