import { colors } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import shadows from './shadows';
import typography from './typography';

const COLORS = {
  primary: '#00FF00',
  secondary: '#005AC2',
  secondaryLight: '#69A6F8',
  info: '#2AA5A7',
  success: '#00ED12',
  warning: '#FC7A57',
  error: '#EB0089'
};

const theme = createTheme({
  palette: {
    vanscend: {
      black: '#0E1116',
      white: '#F8F9FA',
      slate: '#171B21',
      orange: COLORS.warning,
      green: COLORS.primary,
      lightGreen: COLORS.success,
      blue: COLORS.secondary,
      lightBlue: COLORS.secondaryLight,
      aqua: COLORS.info,
      pink: COLORS.error
    },
    background: {
      dark: colors.grey[600],
      light: colors.grey[200],
      default: '#F8F9FA',
      paper: '#F8F9FA',
      secondary: COLORS.secondaryLight
    },
    primary: {
      main: COLORS.primary
    },
    secondary: {
      main: COLORS.secondary,
      light: COLORS.secondaryLight
    },
    info: {
      main: COLORS.info
    },
    success: {
      main: COLORS.success
    },
    warning: {
      main: COLORS.warning
    },
    error: {
      main: COLORS.error
    },
    text: {
      primary: colors.grey[900],
      secondary: colors.grey[600],
      light: colors.grey[100]
    }
  },
  layout: {
    contentWidth: 1800
  },
  shadows,
  typography
});

export default theme;
export { default as GlobalStyles } from './GlobalStyles';
