import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './slices/authSlice';
import profileReducer from './slices/profileSlice';
import cartReducer from './slices/cartSlice';
import productReducer from './slices/productSlice';
import learningReducer from './slices/learningSlice';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  version: 1,
  whitelist: ['theme']
};

const cartPersistConfig = {
  key: 'cart',
  storage: storage,
  keyPrefix: 'redux-'
};

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  cart: persistReducer(cartPersistConfig, cartReducer),
  products: productReducer,
  learning: learningReducer
});

export { rootPersistConfig, rootReducer };
