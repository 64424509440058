import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import MainNavDrawer from '../../components/MainNavDrawer';
import MainTopNav from '../../components/MainTopNav';
import MainFooter from '../../components/MainFooter';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  content: {
    paddingTop: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(8)
    }
  }
}));

const HomeLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <MainTopNav onMobileNavOpen={() => setMobileNavOpen(true)} />
      <MainNavDrawer
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <main className={classes.content}>
        <Outlet />
      </main>
      <MainFooter />
    </div>
  );
};

export default HomeLayout;
