import React from 'react';
import { makeStyles, Card, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: theme.shadows[10],
    borderRadius: 16,
    position: 'relative',
    zIndex: 0
  },
  media: {
    height: 250
  }
}));

const SkeletonProductCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Skeleton animation="wave" variant="rect" className={classes.media} />
      <CardContent>
        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" variant="circle" width={40} height={40} />
      </CardContent>
    </Card>
  );
};

export default SkeletonProductCard;
