import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  makeStyles,
  withStyles,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput
} from '@material-ui/core';
import { FormatCurrencyInput } from '../../../utils';
import { updateDonation } from '../../../redux';

const DonateButton = withStyles((theme) => ({
  root: {
    color: theme.palette.vanscend.green,
    backgroundColor: theme.palette.vanscend.black,
    borderColor: theme.palette.vanscend.green,
    border: '2px outset',
    '&:hover': {
      backgroundColor: 'black'
    }
  }
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.vanscend.white
  },
  button: {
    color: theme.palette.vanscend.black
  },
  donateAmount: {
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.vanscend.white
    },
    '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.vanscend.white
    },
    '& input': {
      color: theme.palette.vanscend.white
    }
  }
}));

const Donate = ({
  className,
  isBgDark = false,
  isDrawer = false,
  handleOpenCart = null
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [amount, setAmount] = useState();

  const handleChangeAmount = (event) => {
    setAmount(Number(event.target.value));
  };

  const handleAddToCart = async () => {
    try {
      await dispatch(updateDonation(amount));
      handleOpenCart && handleOpenCart();
      setAmount();
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={className}
    >
      <Grid container justifyContent="center" spacing={isDrawer ? 1 : 2}>
        <Grid item>
          <DonateButton
            fullWidth
            size="large"
            variant="contained"
            onClick={() => setAmount(0)}
          >
            Donate here!
          </DonateButton>
        </Grid>
      </Grid>
      {amount !== undefined && (
        <Grid item style={{ paddingTop: 20 }}>
          <FormControl variant="outlined" size="small">
            <InputLabel htmlFor="donation-amount" focused>
              Amount
            </InputLabel>
            <OutlinedInput
              className={isBgDark && classes.donateAmount}
              id="donation-amount"
              name="donation-amount"
              value={amount}
              onChange={handleChangeAmount}
              inputComponent={FormatCurrencyInput}
              labelWidth={60}
              onFocus={(event) => {
                event.target.select();
              }}
            />
          </FormControl>
        </Grid>
      )}
      {amount !== undefined && (
        <Box
          textAlign="center"
          style={isDrawer ? { marginTop: 5 } : { marginTop: 10 }}
        >
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size={isDrawer ? 'small' : 'medium'}
            onClick={handleAddToCart}
          >
            Add to Cart
          </Button>
        </Box>
      )}
    </Grid>
  );
};

Donate.propTypes = {
  className: PropTypes.string,
  isBgDark: PropTypes.bool,
  isDrawer: PropTypes.bool,
  handleOpenCart: PropTypes.func
};

export default Donate;
