import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider } from 'formik';
import { Link } from 'react-router-dom';
import {
  Button,
  TextField,
  FormHelperText,
  makeStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: theme.palette.vanscend.green
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: theme.palette.vanscend.green
    }
  },
  focused: {},
  notchedOutline: {},
  submitButton: {
    color: theme.palette.vanscend.black,
    marginTop: -40,
    float: 'right'
  },
  contactForm: {
    padding: theme.spacing(0, 2)
  },
  tncLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem'
    }
  }
}));

const ContactForm = ({ formik }) => {
  const classes = useStyles();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form className={classes.contactForm} noValidate onSubmit={handleSubmit}>
        <TextField
          fullWidth
          variant="outlined"
          type="text"
          label="Name"
          name="name"
          autoComplete="name"
          {...getFieldProps('name')}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
          InputProps={{
            classes: {
              root: classes.root,
              notchedOutline: classes.notchedOutline
            }
          }}
        />
        <TextField
          style={{ marginTop: 25 }}
          fullWidth
          variant="outlined"
          type="email"
          label="Email address"
          name="email"
          autoComplete="email"
          {...getFieldProps('email')}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
          InputProps={{
            classes: {
              root: classes.root,
              notchedOutline: classes.notchedOutline
            }
          }}
        />
        <TextField
          style={{ marginTop: 25 }}
          fullWidth
          variant="outlined"
          multiline
          rows={8}
          label="Comment"
          name="comment"
          autoComplete="comment"
          {...getFieldProps('comment')}
          error={Boolean(touched.comment && errors.comment)}
          helperText={touched.comment && errors.comment}
          InputProps={{
            classes: {
              root: classes.root,
              notchedOutline: classes.notchedOutline
            }
          }}
        />
        <FormControlLabel
          style={{ marginTop: 40 }}
          control={
            <Checkbox
              name="agreeToTaC"
              onClick={() => setTermsAccepted((prev) => !prev)}
            />
          }
          label={
            <div className={classes.tncLabel}>
              <span>I agree to the </span>
              <Link
                to="/terms"
                style={{ color: 'blue', textDecoration: 'underline' }}
              >
                Terms and Conditions
              </Link>
            </div>
          }
        />
        <FormHelperText error={Boolean(errors.afterSubmit)}>
          {errors.afterSubmit && errors.afterSubmit.message}
        </FormHelperText>

        <Button
          className={classes.submitButton}
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting || !termsAccepted}
        >
          Submit
        </Button>
      </Form>
    </FormikProvider>
  );
};

ContactForm.propTypes = {
  formik: PropTypes.object.isRequired
};

export default ContactForm;
