import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    minHeight: '100vh'
  },
  formHeader: {
    marginTop: 20
  },
  headerText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem'
    }
  },
  callout: {
    color: theme.palette.primary.main
  },
  formDescription: {
    marginTop: 10,
    textAlign: 'center'
  },
  descriptionText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  formContent: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '3em',
      minWidth: 400
    },
    minWidth: 650,
    marginTop: '7em'
  },
  formContentInterests: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '3em',
      minWidth: 400
    },
    minWidth: 650,
    marginTop: '5em',
    marginBottom: '5px',
    border: '1px solid black'
  },
  stepper: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400
    },
    padding: theme.spacing(3, 0, 5),
    flexGrow: 1
  },
  formHeaderText: {
    whiteSpace: 'pre-line',
    lineHeight: '2rem'
  },
  formCheckbox: {
    marginRight: '5px'
  }
}));
