import { createSlice } from '@reduxjs/toolkit';

export const learningSlice = createSlice({
  name: 'learning',
  initialState: [],
  reducers: {
    getVideos: (_state, action) => {
      const { videos } = action.payload;
      return videos;
    }
  }
});

const { actions, reducer } = learningSlice;

export const { getVideos } = actions;

export default reducer;
