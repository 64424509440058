import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CloseIcon from '@material-ui/icons/Close';
import {
  makeStyles,
  Box,
  ButtonBase,
  CircularProgress,
  Grid,
  Button,
  Drawer,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import Donate from './Donate';
import { formatCurrency } from '../../../utils';
import { removeFromCart, updateCartItem, updateDonation } from '../../../redux';
import { createCheckoutPage } from '../../../api/supportApi';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 320,
    height: '100%',
    border: 'none',
    overflow: 'hidden'
  },
  cartItem: {
    width: '100%',
    paddingTop: 5,
    paddingBottom: 5
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 300
  },
  image: {
    width: 64,
    height: 64
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));
const ShoppingCart = ({
  isCartOpen,
  onCloseCart,
  products,
  cartItems,
  donation
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [editing, setEditing] = useState('');
  const [item, setItem] = useState({});
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const cartTotal = useMemo(() => {
    let total = cartItems.reduce((acc, curr) => {
      const product = products.find((product) => product.id === curr.product);
      return Number(acc + curr.qty * product.cost);
    }, 0);
    if (donation > 0) {
      total = Number(total + donation);
    }
    return total;
  }, [cartItems, donation, products]);

  const removeItemFromCart = async (cartItem) => {
    try {
      await dispatch(removeFromCart(cartItem));
    } catch (err) {
      alert(err.message);
    }
  };

  const updateCartItemQty = async () => {
    try {
      await dispatch(updateCartItem(item));
      setEditing('');
      setItem({});
    } catch (err) {
      alert(err.message);
    }
  };

  const handleRemoveDonation = async () => {
    try {
      await dispatch(updateDonation(0));
    } catch (err) {
      alert(err.message);
    }
  };

  const startEditing = (item) => {
    setEditing(item.product);
    setItem(item);
  };

  const handleItemQtyChange = (event) => {
    const { value } = event.target;
    setItem((prevItem) => ({
      ...prevItem,
      qty: Number(value)
    }));
  };

  const handleCloseCart = () => {
    setEditing('');
    setItem({});
    onCloseCart();
  };

  const checkout = async () => {
    setLoadingCheckout(true);
    try {
      const url = await createCheckoutPage(cartItems, donation);
      window.location.href = url;
    } catch (err) {
      setLoadingCheckout(false);
      alert(err.message);
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={isCartOpen}
        onClose={handleCloseCart}
        PaperProps={{
          className: classes.root
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ padding: '10px' }}
        >
          <Typography style={{ marginLeft: 1 }} variant="body1">
            Shopping Cart
          </Typography>
          <IconButton onClick={handleCloseCart}>
            <CloseIcon style={{ fontSize: '20px' }} />
          </IconButton>
        </Grid>

        <Divider />

        <Box style={{ height: '100%', overflow: 'auto' }}>
          <Grid container direction="column" style={{ width: '100%' }}>
            {cartItems.length > 0 &&
              cartItems.map((cartItem) => {
                const product = products.find(
                  (product) => product.id === cartItem.product
                );
                const cartItemPrice = formatCurrency(
                  product.cost * cartItem.qty
                );
                return (
                  <div key={cartItem.product} className={classes.cartItem}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <ButtonBase className={classes.image}>
                            <img
                              className={classes.img}
                              alt={product.name}
                              src={
                                product.images.length > 0
                                  ? product.images[0]
                                  : ''
                              }
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={12} sm container>
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            spacing={1}
                          >
                            <Grid item xs>
                              <Typography gutterBottom variant="subtitle1">
                                {product.name}
                              </Typography>
                              {product.size && (
                                <Typography variant="body2" gutterBottom>
                                  Size: {product.size}
                                </Typography>
                              )}
                              {editing === cartItem.product ? (
                                <>
                                  <TextField
                                    id={cartItem.product}
                                    style={{ width: 80 }}
                                    label="Qty"
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    autoFocus
                                    value={item.qty}
                                    onChange={handleItemQtyChange}
                                    inputProps={{ min: 0 }}
                                  />
                                  <Typography
                                    variant="body2"
                                    style={{ cursor: 'pointer' }}
                                    onClick={updateCartItemQty}
                                  >
                                    Apply
                                  </Typography>
                                </>
                              ) : (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Qty: {cartItem.qty}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item container>
                              {editing !== cartItem.product && (
                                <>
                                  <Typography
                                    variant="body2"
                                    style={{
                                      cursor: 'pointer',
                                      marginRight: 10
                                    }}
                                    onClick={() => startEditing(cartItem)}
                                  >
                                    Edit
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => removeItemFromCart(cartItem)}
                                  >
                                    Remove
                                  </Typography>
                                </>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle1">
                              {cartItemPrice}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </div>
                );
              })}

            {donation > 0 && (
              <div className={classes.cartItem}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <ButtonBase className={classes.image}>
                        <img
                          className={classes.img}
                          alt="donation"
                          src="/static/images/shop/donation.jpeg"
                        />
                      </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                          <Typography gutterBottom variant="subtitle1">
                            Donation
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body2"
                            style={{ cursor: 'pointer' }}
                            onClick={handleRemoveDonation}
                          >
                            Remove
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {formatCurrency(donation)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            )}

            {cartItems.length === 0 && donation === 0 && (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%' }}
              >
                <Typography style={{ marginLeft: 1 }} variant="subtitle1">
                  No Items in Cart
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box style={{ padding: '20px' }}>
          {/* <Donate isDrawer={true} /> */}
          <Button
            style={{ marginTop: 10 }}
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={checkout}
            disabled={
              (cartItems.length === 0 && donation === 0) || loadingCheckout
            }
            startIcon={<ShoppingCartIcon />}
          >
            {cartTotal > 0 ? (
              <div>
                Checkout - {formatCurrency(cartTotal)}
                {loadingCheckout && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            ) : (
              <div>Checkout</div>
            )}
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

ShoppingCart.propTypes = {
  isCartOpen: PropTypes.bool,
  onCloseCart: PropTypes.func,
  products: PropTypes.array,
  cartItems: PropTypes.array,
  donation: PropTypes.number
};

export default ShoppingCart;
